import {
    findAllIesRepoNature,
    findIesRepoNatureById,
    findAllIesRepoNaturePaged,
} from '../../_services/ies_repo_nature.service';

export const findAllIesRepoNatureEvent = async () => {
    return await findAllIesRepoNature();
}

export const findIesRepoNatureByIdEvent = async (id) => {
    return await findIesRepoNatureById(id);
}

export const findAllIesRepoNaturePagedEvent = async (page, limit = 8) => {
    return await findAllIesRepoNaturePaged(page, limit);
}



/** Generated by https://github.com/VictorAndres20 code generator for database, NestJS, React */