export const validateIes = (body) => {
    //const {  } = body;
}

export const transformEntityIes = (entity) => {
    let newEnt = {...entity}
    return newEnt;
}

export const buildEmptyIes = () => {
    return {
        uuid: '',
        name: '',
        snies: '',
    };
}



/** Generated by https://github.com/VictorAndres20 code generator for database, NestJS, React */