import { Row } from "antd";
import Yng from "./yng";
import Uniq from "./uniq";
import Opegt from "./opegt";
import Open from "./open";
import Openg from "./openg";
import Scala from "./scala";
import Count from "./count";
import Ynm from "./ynm";

export default function Answers({ question, changeResult }){

    const buildAnswers = (type) => {
        if(type === 'yng'){
            return(
                <Yng 
                    question={question}
                    changeResult={changeResult}
                />
            );
        } else if(type === 'uniq'){
            return(
                <Uniq
                    question={question} 
                    changeResult={changeResult}
                />
            );
        } else if(type === 'opegt'){
            return(
                <Opegt
                    question={question} 
                    changeResult={changeResult}
                />
            );
        } else if(type === 'open'){
            return(
                <Open
                    question={question} 
                    changeResult={changeResult}
                />
            );
        } else if(type === 'openg'){
            return(
                <Openg
                    question={question} 
                    changeResult={changeResult}
                />
            );
        } else if(type === 'scala'){
            return(
                <Scala
                    question={question} 
                    changeResult={changeResult}
                />
            );
        } else if(type === 'count'){
            return(
                <Count
                    question={question} 
                    changeResult={changeResult}
                />
            );
        } else if(type === 'ynm'){
            return(
                <Ynm
                    question={question} 
                    changeResult={changeResult}
                />
            );
        } else {
            return(<>{type} not supported</>);
        }
    }

    return(
        <Row style={{ marginBottom: '20px' }}>
            { buildAnswers(question?.type) }
        </Row>
    );
}