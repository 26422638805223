import { useState } from "react"
import { buildEmptyUserWithIes } from "../../_events/user/model"
import { createAdminUserEvent, createNormalUserEvent } from "../../_events/user/create.event";
import { useNavigate } from "react-router-dom";
import { profile_path, resource_path, uploads_path } from "../../pages/path_pages";
import { message } from "antd";

export const useRegisterUser = () => {

    const navigate = useNavigate();
    
    const [ data, setData ] = useState(buildEmptyUserWithIes());
    const [ loading, setLoading ] = useState(false);

    const changeProperty = (key, value) => {
        let newData = {...data};
        newData[`${key}`] = value;
        setData(newData);
    }

    const changeIesProperty = (key, value) => {
        let newData = {...data};
        let newIes = {...newData.ies_registered};
        newIes[`${key}`] = value;
        newData.ies_registered = newIes;
        setData(newData);
    }

    const navigation = (json) => {
        if(json.root === 1) navigate(profile_path.full_path);
        else if(json.admin === 1) navigate(uploads_path.full_path);
        else navigate(resource_path.full_path);
    }
    
    const createAdminUser = () => {
        setLoading(true);
        createAdminUserEvent(data)
        .then(json => {
            message.success('Registrado');
            setLoading(false);
            navigation(json);
        })
        .catch(err => {
            setLoading(false);
            message.error(err.message);
        });
    }

    const createNormalUser = () => {
        setLoading(true);
        createNormalUserEvent(data)
        .then(json => {
            message.success('Registrado');
            setLoading(false);
            navigation(json);
        })
        .catch(err => {
            setLoading(false);
            message.error(err.message);
        });
    }

    return{
        data, setData, loading, createAdminUser, createNormalUser, changeProperty, changeIesProperty
    };
}