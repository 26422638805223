import React from 'react';
import { getIsAdmin } from '../_utils/storage_handler';

const AdminWrapper = (AuthComponent) => (
    class extends React.Component{
        render(){
            if(validation()){
                return(<AuthComponent {...this.props} />);
            } else {
                return(
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '20px 20px' }}>
                        <span>¡Ingreso no autorizado!</span>
                        <span>Debes ser administrador</span>
                    </div>
                );
            }
        }
    }
);

const validation = () => {
    return getIsAdmin() === 1;
}

export default AdminWrapper;