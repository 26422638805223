import { Button, Col, Input, Row, Select, Spin } from "antd";
import { useState } from "react";
import TermConditionModal from "./terms_condition_modal";
import { useRegisterUser } from "../../_hooks/user/useRegisterUser.hook";
import SelectIesRegistered from "./select_ies_registered";
import SelectIesRepo from "./select_ies";
import SelectIesType from "./select_type_ies";
import SelectIesNature from "./select_nature_ies";
import SelectCityDepartment from "./select_city_department";

export default function RegisterForm(){

    const [ isAdmin, setIsAdmin ] = useState(null);
    const [ acceptTerms, setAcceptTerms ] = useState(false); 
    const [ isModalOpen, setIsModalOpen ] = useState(false);

    const hook = useRegisterUser();
    
    const handleOk = () => {
        setIsModalOpen(false);
    };
    
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return(
        <Row gutter={6}>
            <Col lg={24}>
                <Row gutter={6}>
                    <Col className="gutter-row" lg={8} xs={24}>
                        <div style={{ display: 'flex', flexDirection: 'column', margin: '14px 0' }}>
                            <span style={{ fontSize: '0.85em', fontWeight: 'bold', marginLeft: '10px' }}>Nombres y apellidos completos:</span>
                            <Input placeholder="Nombres y apellidos"
                                value={hook.data.full_name}
                                onChange={(e) => {
                                    hook.changeProperty(`full_name`, e.target.value);
                                }}
                            />
                        </div>
                    </Col>
                    <Col className="gutter-row" lg={8} xs={24}>
                        <div style={{ display: 'flex', flexDirection: 'column', margin: '14px 0' }}>
                            <span style={{ fontSize: '0.85em', fontWeight: 'bold', marginLeft: '10px' }}>Correo institucional:</span>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                <Input
                                    style={{ width: '50%', borderRight: 'none', textAlign: 'end' }}
                                    value={hook.data.email.split("@")[0]}
                                    onChange={(e) => {
                                        hook.changeProperty(`email`, `${e.target.value}@${hook.data.email.split("@")[1]}`);
                                    }}
                                />
                                <Input
                                    style={{ width: '50%' }}
                                    addonBefore={<>@</>}
                                    value={hook.data.email.split("@")[1]}
                                    onChange={(e) => {
                                        hook.changeProperty(`email`, `${hook.data.email.split("@")[0]}@${e.target.value}`);
                                    }}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col className="gutter-row" lg={8} xs={24}>
                        <div style={{ display: 'flex', flexDirection: 'column', margin: '14px 0' }}>
                            <span style={{ fontSize: '0.85em', fontWeight: 'bold', marginLeft: '10px' }}>Teléfono/Contacto:</span>
                            <Input
                                maxLength={10}
                                type="number"
                                style={{ width: '100%' }}
                                value={hook.data.phone}
                                onChange={(e) => {
                                    hook.changeProperty(`phone`, e.target.value);
                                }}
                            />
                        </div>                
                    </Col>
                    <Col className="gutter-row" lg={6} xs={24}>
                        <span style={{ fontSize: '0.85em', fontWeight: 'bold', marginLeft: '10px' }}>Contraseña:</span>
                        <Input.Password
                            style={{ width: '100%' }}
                            value={hook.data.password}
                            onChange={(e) => {
                                hook.changeProperty(`password`, e.target.value);
                            }}
                        />
                    </Col>
                    <Col className="gutter-row" lg={18} xs={24}>
                        <span style={{ fontSize: '0.85em', fontWeight: 'bold', marginLeft: '10px' }}>¿Cargarás archivos de experiencias de una Institución de Educación Superior?</span>
                        <Row>
                            <Col lg={14} xs={24}>
                                <Select
                                    placeholder="Escoge una opción"
                                    style={{ width: '100%' }}
                                    onChange={(e) => {
                                        setIsAdmin(e);
                                        setIsModalOpen(true);
                                    }}
                                    options={[
                                        { value: true, label: 'Sí, veré experiencias y cargaré archivos' },
                                        { value: false, label: 'No, solo quiero ver experiencias' },
                                    ]}
                                />
                            </Col>
                            <Col lg={10} xs={24}>
                            {
                                !acceptTerms &&
                                <Button
                                    type="link"
                                    onClick={() => {
                                        setIsModalOpen(true)
                                    }}
                                >
                                    No se han aceptado los términos y condiciones
                                </Button>
                            }
                            </Col>
                        </Row>
                        <TermConditionModal 
                            isModalOpen={isModalOpen}
                            handleOk={handleOk}
                            handleCancel={handleCancel}
                            setAcceptTerms={setAcceptTerms}
                        />
                    </Col>
                </Row>
                {
                    isAdmin !== null &&
                    <Row>
                        <Col lg={24}>
                        {
                            isAdmin && acceptTerms ?
                            <Row gutter={6}>
                                <Col className="gutter-row" lg={8} xs={24}>
                                    <div style={{ display: 'flex', flexDirection: 'column', margin: '14px 0' }}>
                                        <span style={{ fontSize: '0.85em', fontWeight: 'bold', marginLeft: '10px' }}>Institución de Educación Superior</span>
                                        <SelectIesRepo
                                            allowClear={true}
                                            value={hook.data.ies_registered.ies}
                                            setValue={(e) => {
                                                hook.changeIesProperty(`ies`, e ? e : '');
                                            }}
                                        />
                                    </div>                
                                </Col>
                                <Col className="gutter-row" lg={8} xs={24}>
                                    <div style={{ display: 'flex', flexDirection: 'column', margin: '14px 0' }}>
                                        <span style={{ fontSize: '0.85em', fontWeight: 'bold', marginLeft: '10px' }}>Dependencia/Dirección/Área:</span>
                                        <Input placeholder="Dependencia/Dirección/Área"
                                            value={hook.data.dependency}
                                            onChange={(e) => {
                                                hook.changeProperty(`dependency`, e.target.value);
                                            }}
                                        />
                                    </div>                
                                </Col>
                                <Col className="gutter-row" lg={8} xs={24}>
                                    <div style={{ display: 'flex', flexDirection: 'column', margin: '14px 0' }}>
                                        <span style={{ fontSize: '0.85em', fontWeight: 'bold', marginLeft: '10px' }}>Cargo/Rol:</span>
                                        <Input placeholder="Cargo/Rol"
                                            value={hook.data.job}
                                            onChange={(e) => {
                                                hook.changeProperty(`job`, e.target.value);
                                            }}
                                        />
                                    </div>                  
                                </Col>
                                <Col className="gutter-row" lg={8} xs={24}>
                                    <div style={{ display: 'flex', flexDirection: 'column', margin: '14px 0' }}>
                                        <span style={{ fontSize: '0.85em', fontWeight: 'bold', marginLeft: '10px' }}>Nombre de la Sede:</span>
                                        <Input placeholder="Sede"
                                            value={hook.data.ies_registered.campus}
                                            onChange={(e) => {
                                                let formatted = e ? e.target.value.trim().toUpperCase() : ''
                                                hook.changeIesProperty(`campus`, formatted);
                                            }}
                                        />
                                    </div>                
                                </Col>
                                <Col className="gutter-row" lg={8} xs={24}>
                                    <div style={{ display: 'flex', flexDirection: 'column', margin: '14px 0' }}>
                                        <span style={{ fontSize: '0.85em', fontWeight: 'bold', marginLeft: '10px' }}>Naturaleza de la institución:</span>
                                        <SelectIesNature
                                            allowClear={true}
                                            value={hook.data.ies_registered.ies_nature}
                                            setValue={(value) => {
                                                hook.changeIesProperty(`ies_nature`, value ? value : '');
                                            }}
                                        />
                                    </div>                
                                </Col>
                                <Col className="gutter-row" lg={8} xs={24}>
                                    <div style={{ display: 'flex', flexDirection: 'column', margin: '14px 0' }}>
                                        <span style={{ fontSize: '0.85em', fontWeight: 'bold', marginLeft: '10px' }}>Tipo de institución:</span>
                                        <SelectIesType
                                            allowClear={true}
                                            value={hook.data.ies_registered.ies_type}
                                            setValue={(value) => {
                                                hook.changeIesProperty(`ies_type`, value ? value : '');
                                            }}
                                        />
                                    </div>                  
                                </Col>
                                <Col className="gutter-row" lg={12} xs={24}>
                                    <div style={{ display: 'flex', flexDirection: 'column', margin: '14px 0' }}>
                                        <span style={{ fontSize: '0.85em', fontWeight: 'bold', marginLeft: '10px' }}>Departamento - Municipio/Ciudad/:</span>
                                        <SelectCityDepartment
                                            allowClear={true}
                                            valueCity={hook.data.ies_registered.city}
                                            valueDepartment={hook.data.ies_registered.dep}
                                            setValueCity={(value) => {
                                                hook.changeIesProperty(`city`, value ? value : '');
                                            }}
                                            setValueDepartment={(value) => {
                                                hook.changeIesProperty(`dep`, value ? value : '');
                                            }}
                                        />
                                    </div>                  
                                </Col>
                                <Col className="gutter-row" lg={24} xs={24}>
                                    {

                                        <div style={{ display: 'flex', flexDirection: 'column', margin: '14px 0', padding: '0 40px' }}>
                                            {
                                                hook.loading ?
                                                <Spin />
                                                :
                                                <Button 
                                                    onClick={hook.createAdminUser}
                                                    type="primary">
                                                        Crear administrador IES
                                                </Button>
                                            }
                                        </div>
                                    }
                                </Col>
                            </Row> 
                            : !isAdmin && acceptTerms ?
                            <Row gutter={6}>
                                <Col className="gutter-row" lg={8} xs={24}>
                                    <div style={{ display: 'flex', flexDirection: 'column', margin: '14px 0' }}>
                                        <span style={{ fontSize: '0.85em', fontWeight: 'bold', marginLeft: '10px' }}>Institución de Educación Superior</span>
                                        <SelectIesRegistered
                                            value={hook.data.ies}
                                            allowClear={true}
                                            setValue={(value) => {
                                                hook.changeProperty(`ies`, value ? value : '');
                                            }}
                                        />
                                    </div>                
                                </Col>
                                <Col className="gutter-row" lg={8} xs={24}>
                                    <div style={{ display: 'flex', flexDirection: 'column', margin: '14px 0' }}>
                                        <span style={{ fontSize: '0.85em', fontWeight: 'bold', marginLeft: '10px' }}>Dependencia/Dirección/Área:</span>
                                        <Input placeholder="Dependencia/Dirección/Área"
                                            value={hook.data.dependency}
                                            onChange={(e) => {
                                                hook.changeProperty(`dependency`, e.target.value);
                                            }}
                                        />
                                    </div>                
                                </Col>
                                <Col className="gutter-row" lg={8} xs={24}>
                                    <div style={{ display: 'flex', flexDirection: 'column', margin: '14px 0' }}>
                                        <span style={{ fontSize: '0.85em', fontWeight: 'bold', marginLeft: '10px' }}>Cargo/Rol:</span>
                                        <Input placeholder="Cargo/Rol"
                                            value={hook.data.job}
                                            onChange={(e) => {
                                                hook.changeProperty(`job`, e.target.value);
                                            }}
                                        />
                                    </div>                  
                                </Col>
                                <Col className="gutter-row" lg={24} xs={24}>
                                    {
                                        <div style={{ display: 'flex', flexDirection: 'column', margin: '14px 0' }}>
                                            {
                                                hook.loading ?
                                                <Spin />
                                                :
                                                <Button 
                                                    onClick={hook.createNormalUser}
                                                    type="primary">
                                                        Crear usuario
                                                </Button>
                                            }
                                        </div>
                                    }
                                </Col>
                            </Row> 
                            :
                            <></>
                        }
                        </Col>
                    </Row>
                }
            </Col>
        </Row>
    );
}