import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { buildEmptyIes } from "../../_events/ies/model";
import { useNavigate } from "react-router-dom";
import { survey_path } from "../path_pages";
import { findAllQuestionEvent } from "../../_events/question/find.event";
import { putCampus, putIes, putIesNaturaly, putIesType, putPerson, putQuestions } from "../../_utils/storage_handler";
import { findAllIesEvent } from "../../_events/ies/find.event";
import Select from 'react-select';
import { buildEmptyIesNaturaly } from "../../_events/ies_naturaly/model";
import { buildEmptyIesType } from "../../_events/ies_type/model";
import { buildEmptyPerson } from "../../_events/person/model";
import { findAllIesNaturalyEvent } from "../../_events/ies_naturaly/find.event";
import { findAllIesTypeEvent } from "../../_events/ies_type/find.event";
import { findAllJobPositionEvent } from "../../_events/job_position/find.event";
import MainHeader from "../../widgets /headers/main_header";

export default function StartSurveyPage() {

    const [ entity, setEntity ] = React.useState(buildEmptyIes());
    const [ campus, setCampus ] = React.useState('');
    const [ ies_naturaly, setIesNaturaly ] = React.useState(buildEmptyIesNaturaly());
    const [ ies_type, setIesType ] = React.useState(buildEmptyIesType());
    const [ person, setPerson ] = React.useState(buildEmptyPerson());
    const [ authorize, setAuthorize ] = React.useState(false);
    const [ ies, setIes ] = React.useState([]);
    const [ naturalies, setNaturalies ] = React.useState([]);
    const [ types, setTypes ] = React.useState([]);
    const [ jobs, setJobs ] = React.useState([]);
    const navigate = useNavigate();

    const goSurvey = () => {
        if(entity.name === '') alert("Nombre IES vacío");
        else if(entity.snies === '') alert("Código SNIES vacío");
        else if(ies_naturaly.name === '') alert("Naturaleza de IES vacía");
        else if(ies_type.name === '') alert("Tipo de IES vacío");
        else if(person.email === '') alert("Correo vacío");
        else if(person.job_position === '') alert("Cargo vacío");
        else{
            putIes(entity);
            putCampus(campus);
            putIesNaturaly(ies_naturaly);
            putIesType(ies_type);
            putPerson(person);
            navigate(survey_path.full_path);
        }
        
    }

    React.useEffect(() => {
        findAllQuestionEvent()
        .then(json => {
            putQuestions(json.list);
        })
        .catch(err => {
            alert(err.message);
        });
        findAllIesEvent()
        .then(json => {
            setIes(json.list);
        })
        .catch(err => {
            alert(err.message);
        });

        findAllIesNaturalyEvent()
        .then(json => {
            setNaturalies(json.list);
        })
        .catch(err => {
            alert(err.message);
        });

        findAllIesTypeEvent()
        .then(json => {
            setTypes(json.list);
        })
        .catch(err => {
            alert(err.message);
        });

        findAllJobPositionEvent()
        .then(json => {
            setJobs(json.list);
        })
        .catch(err => {
            alert(err.message);
        });
    }, []);

    return(
        <div>
            <MainHeader />
            <Container>            
                <Row>
                    <Col lg={12}>
                        <div className="flex-col flex-center" style={{ margin: '40px 0' }}>
                            <div style={{ fontSize: '1.5em', fontWeight: 'bold', margin: '20px 0', textAlign: 'center' }}>
                            IDENTIFICACIÓN DE ACCIONES DE PERMANENCIA Y GRADUACIÓN EN LAS IES
                            </div>
                            <div style={{ fontSize: '1em', textAlign: 'center', margin: '10px 0' }}>
                            La Asociación Colombiana de Universidades (ASCUN) suscribió el contrato número co1.pccntr.5354594 de 2023, por medio del cual se busca la actualización de los lineamientos contenidos en la guía para la implementación del modelo de gestión de la permanencia y la graduación estudiantil en instituciones de educación superior y la construcción de herramientas de orientación socio ocupacional en clave de trayectoria educativa. Dentro de las actividades previstas para lograr este propósito, se realizará un diagnóstico sobre el nivel de implementación de la guía en las IES del país.
                            </div>
                            <div style={{ fontSize: '1em', textAlign: 'center', margin: '10px 0' }}>
                            Queremos realizar una identificación sobre el nivel de apropiación y conocimiento de las estrategias establecidas en Guía. Por ello, les invitamos de manera atenta a suministrar la siguiente información, con la cual podremos diseñar el instrumento para el diagnóstico de manera más acertada. 
                            </div>
                            <div style={{ fontSize: '1em', textAlign: 'center', margin: '10px 0' }}>
                            Agradecemos su atención a la presente.
                            </div>
                        </div>
                    </Col>
                    <Col lg={12}>
                        <div className="flex-col flex-center" style={{ marginTop: '40px', width: '100%' }}>
                        <div  style={{ fontWeight: 'bold' }}>Institución de Educación Superior</div>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="flex-col flex-center" style={{ margin: '10px 0', width: '100%' }}>
                        <div id="form" style={{ fontWeight: 'bold' }}>(*) Institución de Educación Superior:</div>
                        <Select
                            styles={{
                                control: (styles) => ({ ...styles, height: '33px', width: '350px', padding: '0px 15px', cursor: 'pointer' }),
                                menu: (styles) => ({ ...styles, maxHeight: '250px' }), 
                                
                            }}
                            isClearable={true}
                            isSearchable={true}
                            options={ies.map(i => ({ value: i, label: `${i.uuid} - ${i.name}` }))}
                            onChange={(e) => {
                                if(e){
                                    setEntity(e.value);
                                }  else {
                                    setEntity(buildEmptyIes());
                                }                              
                            }}
                        />
                        <Form.Control 
                            style={{ width: '350px',margin: '10px 0' }}
                            placeholder="Escriba aquí la SEDE si se necesita"
                            type="text"
                            value={campus}
                            onChange={(e) => {
                                setCampus(e.target.value);
                            }}
                        />
                        </div>
                    </Col>
                    <Col lg={3}>
                    <div className="flex-col flex-center" style={{ margin: '10px 0', width: '100%' }}>
                        <div  style={{ fontWeight: 'bold' }}>(*) Naturaleza de la Institución:</div>
                        <Select
                            styles={{
                                control: (styles) => ({ ...styles, height: '33px', width: '200px', padding: '0px 15px', cursor: 'pointer' }),
                                menu: (styles) => ({ ...styles, maxHeight: '250px' }), 
                                
                            }}
                            isClearable={true}
                            isSearchable={true}
                            options={naturalies.map(i => ({ value: i, label: `${i.name}` }))}
                            onChange={(e) => {
                                if(e){
                                    setIesNaturaly(e.value);
                                }  else {
                                    setIesNaturaly(buildEmptyIesNaturaly());
                                }                              
                            }}
                        />
                        </div>
                    </Col>
                    <Col lg={3}>
                    <div className="flex-col flex-center" style={{ margin: '10px 0', width: '100%' }}>
                        <div  style={{ fontWeight: 'bold' }}>(*) Tipo de Institución:</div>
                        <Select
                            styles={{
                                control: (styles) => ({ ...styles, height: '33px', width: '200px', padding: '0px 15px', cursor: 'pointer' }),
                                menu: (styles) => ({ ...styles, maxHeight: '250px' }), 
                                
                            }}
                            isClearable={true}
                            isSearchable={true}
                            options={types.map(i => ({ value: i, label: `${i.name}` }))}
                            onChange={(e) => {
                                if(e){
                                    setIesType(e.value);
                                }  else {
                                    setIesType(buildEmptyIesType());
                                }                              
                            }}
                        />
                        </div>
                    </Col>
                    <Col lg={12}>
                        <div className="flex-col flex-center" style={{ marginTop: '40px', width: '100%' }}>
                        <div  style={{ fontWeight: 'bold' }}>Profesional que responde la encuesta</div>
                        </div>
                    </Col>
                    <Col lg={3}>
                        <div className="flex-col flex-center" style={{ margin: '10px 0', width: '100%' }}>
                        <div  style={{ fontWeight: 'bold' }}>(*) Correo electrónico:</div>
                        <Form.Control
                            value={ person ? person.email : '' }
                            onChange={(e) => {
                                let ne = { ...person };
                                ne.email = e.target.value;
                                setPerson(ne);
                            }}
                            type="email"
                        />
                        </div>
                    </Col>
                    <Col lg={3}>
                        <div className="flex-col flex-center" style={{ margin: '10px 0', width: '100%' }}>
                        <div  style={{ fontWeight: 'bold' }}>Teléfono/Contacto:</div>
                        <Form.Control
                            value={ person ? person.phone : '' }
                            onChange={(e) => {
                                let ne = { ...person };
                                ne.phone = e.target.value;
                                setPerson(ne);
                            }}
                            type="number"
                        />
                        </div>
                    </Col>
                    <Col lg={6}>
                    <div className="flex-col flex-center" style={{ margin: '10px 0', width: '100%' }}>
                        <div  style={{ fontWeight: 'bold' }}>(*) Cargo o dependencia en la institución:</div>
                        <Select
                            styles={{
                                control: (styles) => ({ ...styles, height: '33px', width: '350px', padding: '0px 15px', cursor: 'pointer' }),
                                menu: (styles) => ({ ...styles, maxHeight: '250px' }),                                
                            }}
                            isClearable={true}
                            isSearchable={true}
                            options={jobs.map(i => ({ value: i, label: `${i.name}` }))}
                            onChange={(e) => {
                                let ne = { ...person };
                                if(e){
                                    ne.job_position = e.value.uuid;
                                    if(e.value.uuid !== 'o') ne.job_position_open = '';
                                }  else {
                                    ne.job_position = '';
                                    ne.job_position_open = '';
                                } 
                                setPerson(ne);
                            }}
                        />
                        {
                            person.job_position === 'o' &&
                            <div style={{ width: '350px', marginTop: '10px' }}>
                                <span>Cuál:</span>
                                <Form.Control 
                                    value={ person ? person.job_position_open : '' }
                                    onChange={(e) => {
                                        let ne = { ...person };
                                        ne.job_position_open = e.target.value;
                                        setPerson(ne);
                                    }}
                                    type="text"
                                />
                            </div>
                        }
                        </div>
                    </Col>
                    <Col lg={12}>
                        <div className="flex-col flex-center" style={{ margin: '40px 0' }}>
                            <div  style={{ fontWeight: 'bold', marginTop: '20px' }}>Autorización de tratamiento de datos</div>
                            <Form.Check
                                onChange={(e) => {
                                    setAuthorize(e.target.checked);
                                }}
                                id="checkauth"
                                checked = {authorize}
                                type={'checkbox'}
                                label={`Sí autorizo`}
                            />
                        </div>
                        <div className="flex-col flex-center" style={{ margin: '8px 0' }}>
                            { 
                                authorize ?
                                <Button
                                    onClick={() => {
                                        goSurvey();
                                    }}
                                >
                                    Continuar
                                </Button>
                                :
                                <Button disabled variant="light">
                                    Continuar
                                </Button>
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
        
    );
}