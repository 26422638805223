import {
    findAllComponent,
    findComponentById,
    findAllComponentPaged,
} from '../../_services/component.service';

export const findAllComponentEvent = async () => {
    return await findAllComponent();
}

export const findComponentByIdEvent = async (id) => {
    return await findComponentById(id);
}

export const findAllComponentPagedEvent = async (page, limit = 8) => {
    return await findAllComponentPaged(page, limit);
}



/** Generated by https://github.com/VictorAndres20 code generator for database, NestJS, React */