import {
    findAllAlternativeStates,
    findAlternativeStatesById,
    findAllAlternativeStatesPaged,
} from '../../_services/alternative_states.service';

export const findAllAlternativeStatesEvent = async () => {
    return await findAllAlternativeStates();
}

export const findAlternativeStatesByIdEvent = async (id) => {
    return await findAlternativeStatesById(id);
}

export const findAllAlternativeStatesPagedEvent = async (page, limit = 8) => {
    return await findAllAlternativeStatesPaged(page, limit);
}



/** Generated by https://github.com/VictorAndres20 code generator for database, NestJS, React */