export const validateIesType = (body) => {
    //const {  } = body;
}

export const transformEntityIesType = (entity) => {
    let newEnt = {...entity}
    return newEnt;
}

export const buildEmptyIesType = () => {
    return {
        uuid: '',
        name: '',
    };
}



/** Generated by https://github.com/VictorAndres20 code generator for database, NestJS, React */