import { Col, Row } from "antd";
import { useResourceCategory } from "../../_hooks/resource/useResourceCategory.hook";
import Info from "./info";
import SelectLabel from "./select_label";

const count = 6

export default function ResourceCategoryPage(){

    const dataHook = useResourceCategory(0, count);  

    return(
        <section style={{ marginTop: '-80px' }} className="explore-section section-padding" id="section_2">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center" >
                        <h2 className="mb-4">Experiencias y recursos</h2>
                        <h6 className="mb-4">Búsqueda por categoría</h6>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <Row>
                    <Col lg={6} xs={24}>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            <span style={{ fontSize: '0.85em', fontWeight: 'bold', marginLeft: '10px' }}>Filtrar categoría:</span>
                            <div style={{ margin: '20px 20px', display: 'flex', flexDirection: 'column' }}>
                                <SelectLabel                                
                                    allowClear={true}
                                    value={dataHook.filter}
                                    setValue={(value) => {
                                        if(value === undefined) dataHook.setFilter('');
                                        else dataHook.setFilter(value);
                                    }}
                                />
                            </div>
                        </div>
                    </Col>
                    
                </Row>
                
                
            </div>
            
            <Info dataHook={dataHook} count={count} />
        </section>
    );
}