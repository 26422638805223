import {
    findAllQuestion,
    findQuestionById,
    findAllQuestionPaged,
} from '../../_services/question.service';

export const findAllQuestionEvent = async () => {
    return await findAllQuestion();
}

export const findQuestionByIdEvent = async (id) => {
    return await findQuestionById(id);
}

export const findAllQuestionPagedEvent = async (page, limit = 8) => {
    return await findAllQuestionPaged(page, limit);
}



/** Generated by https://github.com/VictorAndres20 code generator for database, NestJS, React */