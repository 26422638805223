export const validateResource = (body) => {
    const { implementation_time, focus_people, keywords, author1, job1, name, description, path, type, label } = body;
    
    if(label === null || label === '') throw new Error('Categoría vacía');
    if(implementation_time === null || implementation_time === '') throw new Error('Timepo de implementación vacío');
    if(focus_people === null || focus_people === '') throw new Error('Población focalizada vacía');
    if(keywords === null) throw new Error('Palabras clave vacías');
    if(keywords.length === 0) throw new Error('Palabras clave vacías');
    if(name === null || name === '') throw new Error('Estrategía de visualización vacía');
    if(description === null || description === '') throw new Error('Contextualización vacía');
    if(author1 === null || author1 === '') throw new Error('Se necesita almenos un autor');
    if(job1 === null || job1 === '') throw new Error('Se necesita almenos un autor');
    if(type === null || type === '') throw new Error('Formato vacío');
    if(path === null || path === '') throw new Error('Archivo vacío');   
}

export const transformEntityResource = (entity) => {
    let newEnt = {...entity}
    newEnt.user_upload = newEnt.user_upload?.uuid;
    newEnt.ies = newEnt.ies?.uuid;
    newEnt.type = newEnt.type?.cod;
    newEnt.label = newEnt.label?.cod;
    return newEnt;
}

export const buildEmptyResource = () => {
    return {
        uuid: '',
        name: '',
        description: '',
        path: '',
        user_upload: '',
        ies: '',
        type: '',
        label: '',
    };
}



/** Generated by https://github.com/VictorAndres20 code generator for database, NestJS, React */