import {
    findAllPerson,
    findPersonById,
    findAllPersonPaged,
    buildExcelPerson,
} from '../../_services/person.service';

export const findAllPersonEvent = async () => {
    return await findAllPerson();
}

export const buildExcelPersonEvent = async () => {
    return await buildExcelPerson();
}

export const findPersonByIdEvent = async (id) => {
    return await findPersonById(id);
}

export const findAllPersonPagedEvent = async (page, limit = 8) => {
    return await findAllPersonPaged(page, limit);
}



/** Generated by https://github.com/VictorAndres20 code generator for database, NestJS, React */