import { Col, Input } from "antd";

export default function Open({ question, changeResult }){

    return(
        <Col lg={24} xs={24}>
            <Input
                value={ question?.results[0]?.value }
                onChange={(e) => {
                    changeResult(question?.type, question?.answers[0]?.cod, e.target.value ? e.target.value.toUpperCase() : e.target.value);
                }}
            />           
        </Col>
    );
}