import React from "react";
import Logo1 from '../../_assets/img/mineducacion_logo1.svg';
import Logo2 from '../../_assets/img/ascun.png';
import Logo3 from '../../_assets/img/mineducacion_logo2.png';

export default function AHeader(){
    return(
        <div className="flex-row flex-center" style={{ width: '100%', backgroundColor: '#fff', padding: '20px 0' }}>
            <div className="flex-col flex-center" style={{ width: '33%' }}>
                <img width={100} src={Logo1} alt="ASCUN"/>
            </div>
            <div className="flex-col flex-center" style={{ width: '33%' }}>
                <img width={100} src={Logo2} alt="ASCUN"/>
            </div>
            <div className="flex-col flex-center" style={{ width: '33%' }}>
                <img width={100} src={Logo3} alt="ASCUN"/>
            </div>
        </div>
    );
} 