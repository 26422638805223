import { Col, Radio } from "antd";

export default function Scala({ question, changeResult }){

    return(
        <Col lg={24} xs={24}>
            <table style={{ width: '100%' }}>
                <thead style={{ width: '100%' }}>
                    <tr style={{ width: '100%' }}>
                        <th style={{ width: '50%', borderBottom: '1px solid' }}></th>
                        <th style={{ width: '10%', borderBottom: '1px solid' }}><span style={{ fontSize: '0.8em', fontWeight: 'bold' }}>Muy alta</span></th>
                        <th style={{ width: '10%', borderBottom: '1px solid' }}><span style={{ fontSize: '0.8em', fontWeight: 'bold' }}>Alta</span></th>
                        <th style={{ width: '10%', borderBottom: '1px solid' }}><span style={{ fontSize: '0.8em', fontWeight: 'bold' }}>Media</span></th>
                        <th style={{ width: '10%', borderBottom: '1px solid' }}><span style={{ fontSize: '0.8em', fontWeight: 'bold' }}>Baja</span></th>
                        <th style={{ width: '10%', borderBottom: '1px solid' }}><span style={{ fontSize: '0.8em', fontWeight: 'bold' }}>Muy baja</span></th>
                    </tr>
                </thead>
            <tbody style={{ width: '100%' }}>
            {
                question?.answers.map((a, key) => {
                    return(
                        <tr key={`yng_tr_${key}`} style={{ width: '100%' }}>
                            <td style={{ width: '50%', borderBottom: '1px solid' }}>{a.label}</td>
                            <td style={{ width: '10%', borderBottom: '1px solid' }}>
                                <Radio
                                    checked={question?.results.findIndex( r => r.answer === a.cod && r.value === '5' ) !== -1 }
                                    onChange={(e) => {
                                        changeResult(question?.type, a.cod, '5');
                                    }}
                                >
                                    5
                                </Radio>
                            </td>
                            <td style={{ width: '10%', borderBottom: '1px solid' }}>
                                <Radio
                                    checked={question?.results.findIndex( r => r.answer === a.cod && r.value === '4' ) !== -1 }
                                    onChange={(e) => {
                                        changeResult(question?.type, a.cod, '4');
                                    }}
                                >
                                    4
                                </Radio>
                            </td>
                            <td style={{ width: '10%', borderBottom: '1px solid'}}>
                                <Radio
                                    checked={question?.results.findIndex( r => r.answer === a.cod && r.value === '3' ) !== -1 }
                                    onChange={(e) => {
                                        changeResult(question?.type, a.cod, '3');
                                    }}
                                >
                                    3
                                </Radio>
                            </td>
                            <td style={{ width: '10%', borderBottom: '1px solid' }}>
                                <Radio
                                    checked={question?.results.findIndex( r => r.answer === a.cod && r.value === '2' ) !== -1 }
                                    onChange={(e) => {
                                        changeResult(question?.type, a.cod, '2');
                                    }}
                                >
                                    2
                                </Radio>
                            </td>
                            <td style={{ width: '10%', borderBottom: '1px solid' }}>
                                <Radio
                                    checked={question?.results.findIndex( r => r.answer === a.cod && r.value === '1' ) !== -1 }
                                    onChange={(e) => {
                                        changeResult(question?.type, a.cod, '1');
                                    }}
                                >
                                    1
                                </Radio>
                            </td>
                        </tr>
                    );
                })
            }
            </tbody>
            </table>            
        </Col>
    );
}