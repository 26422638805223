export const groups = {
    0: {
        cod: 'P',
        desc: 'Se entiende como la capacidad de la IES de establecer objetivos, metas, desarrollo de programas y acciones y las definiciones conceptuales para deserción, permanencia y graduación oportuna. Con estas preguntas se identifica si la permanencia es considerada por las directivas institucionales como un propósito superior al cual se articulan diversas estrategias.',
        desc2: '',
    },
    1: {
        cod: 'R',
        desc: 'Se refieren a disponibilidad de financiamiento, personal, tecnología e infraestructura física que le permitiría a la IES operar el modelo de gestión para la permanencia. Como variables dentro de este factor se destaca la cantidad de personas en el área/unidad de permanencia, cualificación y experiencia, rotación, diversidad, presupuesto, fuentes de ingreso, gastos, hardware, software y sistemas de captura de información utilizados, TIC´s, instalaciones y aulas, talleres, laboratorios, equipos, áreas de investigación y desarrollo y recursos de aprendizaje, planes de contingencia.',
        desc2: '',
    },
    2: {
        cod: 'Cu',
        desc: 'Este factor permite identificar si se ha creado un ambiente institucional para la permanencia y graduación estudiantil a través de valores, normas, estrategias y experiencia estudiantil.',
        desc2: '',
    },
    3: {
        cod: 'Ef',
        desc: 'Este factor da cuenta de indicadores de logro de las estrategias de permanencia a través de la evaluación de políticas, programas, acciones, prácticas y servicios. Entre estos indicadores se encuentran: tiempos de permanencia, tasas de permanencia por cohorte, tasas de graduación por cohorte y por período, seguimiento a trayectorias estudiantiles, análisis de compromiso académico, evaluación de acciones que promueven el sentido de pertenencia y jalonan la persistencia.  En cuanto al desempeño académico se consideran la evaluación de los resultados académicos generales de los estudiantes, acceso a recursos académicos desde la docencia, la investigación y la extensión, resultados de la evaluación de tutorías y mentorías, cobertura de los programas de orientación socio ocupacional en todo el ciclo formativo, evaluación y disponibilidad de recursos financieros, disponibilidad y acceso a programas de bienestar, identificación de factores y señales de riesgo, evaluación robusta del impacto de los programas para permanencia, bases de datos adecuadas para el análisis de permanencia, estrategias de monitoreo y seguimiento, adaptaciones, flexibilidad y cambio en los programas a partir de los resultados de investigación y mediciones. Encuestas y análisis cualitativos con estudiantes y profesores sobre factores de riesgo que afectan la permanencia y graduación estudiantil, construcción de indicadores de equidad, diversidad y educación inclusiva.',
        desc2: '',
    },
    4: {
        cod: 'Rc',
        desc: '',
        desc2: '',
    },
}