import { useEffect, useState } from "react";
import { message } from "antd";
import { findAllResourcePaged, findAllResourceAtentionPaged } from "../../_services/resource.service";

export const useResourceAtention = (page, count = 10) => {

    const [filter, setFilter] = useState('');
    const [actualPage, setActualPage] = useState(page);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([[],[]]);

    const loadData = (p, c, f) => {
        setLoading(true);
        if(f !== ''){
            findAllResourceAtentionPaged(p, c, f)
            .then(json => {
                setData(json.paged);
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                message.error(err.message);
            });
        } else {
            findAllResourcePaged(p, c)
            .then(json => {
                setData(json.paged);
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                message.error(err.message);
            });
        }
    }

    useEffect(() => {
        loadData(actualPage, count, filter);
    }, [actualPage, count, filter]);

    return {
        actualPage, filter, setActualPage, loading, data, loadData, setFilter
    };
}