import { Button, Col, Input, Modal, Row, Spin } from "antd";
import { useState } from "react";
import { useChangePassword } from "../../_hooks/user/useChangePassword.hook";

export default function RestorePassword(){

    const [isModalOpen, setIsModalOpen] = useState(false);

    const hook = useChangePassword();

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return(
        <div>
            <Button
                style={{ width: '100%', marginTop: '20px' }}
                type="link"
                onClick={showModal}
            >
                ¿Olvidaste la contraseña?
            </Button>
            <Modal title="Generar nueva contraseña" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={<></>}>
                <Row>
                    <Col lg={24} xs={24}>
                        <span style={{ fontSize: '0.85em', fontWeight: 'bold', marginLeft: '10px' }}>Correo institucional</span>
                        <Input 
                            value={hook.email}
                            onChange={(e) => {
                                hook.setEmail(e.target.value);
                            }}
                        />
                    </Col>
                    <Col style={{ marginTop: '20px' }} lg={24} xs={24}>
                        {
                            hook.loading ?
                            <Spin />
                            :
                            <Button
                                type="primary"
                                onClick={hook.changePassword}
                            >
                                Solicitar cambio de contraseña
                            </Button>
                        }
                    </Col>
                </Row>
            </Modal>
        </div>
    );
}