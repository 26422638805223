import { useEffect, useState } from "react"
import { buildEmptyUserWithIes, transformEntityUser } from "../../_events/user/model"
import { editUserEvent } from "../../_events/user/create.event";
import { message } from "antd";
import { findUserByIdEvent } from "../../_events/user/find.event";
import { getUserId } from "../../_utils/storage_handler";

export const useUpdateUser = () => {
    
    const [ data, setData ] = useState(buildEmptyUserWithIes());
    const [ loading, setLoading ] = useState(false);

    const update = () => {
        setLoading(true);
        editUserEvent(data.uuid, data)
        .then(json => {
            setLoading(false);
            message.success('Actualizado');
        })
        .catch(err => {
            setLoading(false);
            message.error(err.message);
        });
    }

    const changeProperty = (key, value) => {
        let newData = {...data};
        newData[`${key}`] = value;
        setData(newData);
    }

    useEffect(() => {
        findUserByIdEvent(getUserId())
        .then(json => {
            setData(transformEntityUser(json.data));
        })
        .catch(err => {
            message.error(err.message);
        });
    }, []);

    return{
        data, setData, loading, update, changeProperty,
    };
}