import {
    createUser,
    editUser,
    createAdminUser,
    createNormalUser,
    changeAdminUser,
} from '../../_services/user.service';
import { putIesId, putIsAdmin, putIsRoot, putToken, putUserId } from '../../_utils/storage_handler';
import { validateEdition, validateUser, validateUserAdmin } from './model';

export const createUserEvent = async (body) => {
    validateUser(body);
    return await createUser(body);
}

export const createAdminUserEvent = async (body) => {
    validateUserAdmin(body);
    let json = await createAdminUser(body);
    const admin = json.data.admin;
    const root = json.data.root;
    putToken(json.data.token);
    putUserId(json.data.uuid);
    putIsAdmin(admin);
    putIsRoot(root);
    putIesId(json.data.ies);
    return {
        admin, root
    };
}

export const createNormalUserEvent = async (body) => {
    validateUser(body);
    let json = await createNormalUser(body);
    const admin = json.data.admin;
    const root = json.data.root;
    putToken(json.data.token);
    putUserId(json.data.uuid);
    putIsAdmin(admin);
    putIsRoot(root);
    putIesId(json.data.ies);
    return {
        admin, root
    };
}

export const editUserEvent = async (id, body) => {
    validateEdition(body);
    return await editUser(id, body);
}

export const changeAdminUserEvent = async (body) => {
    return await changeAdminUser(body);
}



/** Generated by https://github.com/VictorAndres20 code generator for database, NestJS, React */