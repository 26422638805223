import React from "react";
import { Button } from "react-bootstrap";

export default function MainHeader({ path }){
    return(
        <div className="flex-col flex-center" style={{ width: '100%', backgroundColor: '#fff', padding: '20px 0' }}>
            <a href="#form">
            <img className="vp-banner" src={require(`../../_assets/img/${path ? path : 'banner.jpeg'}`)}  alt="ASCUN"/>
            </a>

            <Button 
                onClick={() => {
                    let a = document.createElement('a');
                    a.href = 'https://www.mineducacion.gov.co/portal/micrositios-superior/Publicaciones-Educacion-Superior/356272:Guia-para-la-Implementacion-de-Educacion-Superior-del-Modelo-de-Gestion-de-Permanencia-y-Graduacion-Estudiantil-en-Instituciones-de-Educacion-Superior'
                    a.target = '_blank';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                }}  
                variant="default" style={{ color: '#3C5EA5' }}>
                Consulte la guía aquí
            </Button>
        </div>
    );
} 