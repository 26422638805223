import { useEffect, useState } from "react";
import { getAGroupIdx, getAQuestionIdx, getAquestions, putAquestions } from "../../_utils/storage_handler";
import { message } from "antd";

export const useAresult = () => {

    const [ questions, setQuestions ] = useState([]);

    const changeResult = (type, answer, value) => {
        if(value.length > 499){
            message.error('La respuesta excede 500 caracteres');
        } else {
            let groupIndex = getAGroupIdx();
            let questionIndex = getAQuestionIdx(); 
            let newQuestions = [...questions];
            let newQuestion = {...newQuestions[groupIndex]?.questions[questionIndex]};
            let newResults = [...newQuestion.results];
            if(type === 'yng' || type === 'opegt' || type === 'open' || type === 'openg' || type === 'scala' || type === 'count' || type === 'ynm'){
                let resultIndex = newResults.findIndex( r => r.answer === answer );
                if(resultIndex !== -1){
                    let newResult = {...newResults[resultIndex]};
                    newResult.value = value;
                    newResult.addition_value = '';
                    newResults[resultIndex] = newResult;                
                }
            } else if(type === 'uniq'){
                if(newResults.length > 0){
                    let newResult = {...newResults[0]};
                    newResult.answer = answer;
                    newResult.addition_value = '';
                    newResult.value = value;
                    newResults[0] = newResult;
                }
            } else if(type === 'uniqo' || type === 'yngo'){
                let resultIndex = newResults.findIndex( r => r.answer === answer );
                if(resultIndex !== -1){
                    let newResult = {...newResults[resultIndex]};
                    newResult.addition_value = value;
                    newResults[resultIndex] = newResult;                
                }
            }
            newQuestion.results = newResults;
            newQuestions[groupIndex].questions[questionIndex] = newQuestion;
            putAquestions(newQuestions);
            setQuestions(newQuestions);
        }
    }

    useEffect(() => {
        setQuestions(getAquestions());
    }, []);

    return {
        questions, changeResult
    };
}