import {
    findAllJobPosition,
    findJobPositionById,
    findAllJobPositionPaged,
} from '../../_services/job_position.service';

export const findAllJobPositionEvent = async () => {
    return await findAllJobPosition();
}

export const findJobPositionByIdEvent = async (id) => {
    return await findJobPositionById(id);
}

export const findAllJobPositionPagedEvent = async (page, limit = 8) => {
    return await findAllJobPositionPaged(page, limit);
}



/** Generated by https://github.com/VictorAndres20 code generator for database, NestJS, React */