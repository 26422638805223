import { Button, Card, Input, Spin } from "antd";
import { UserOutlined, LockOutlined, LoadingOutlined } from '@ant-design/icons';
import { useLogin } from "../../_hooks/login/useLogin.hook";
import { enterKeyHandler } from "../../_utils/keyboard_events";
import RestorePassword from "./restore_password_modal";

export default function LoginForm(){

    const loginHook = useLogin();

    const pressEnter = (e) => {
        enterKeyHandler(e, loginHook.login);
    }

    return(
        <Card style={{ width: 330 }}>
            <div style={{ margin: '10px 0' }}>
                <div style={{ fontSize: '0.95em', fontWeight: 'bold' }}>Correo:</div>
                <Input size="large" prefix={<UserOutlined />} 
                    value={loginHook.userInput}
                    onChange={(e) => {
                        loginHook.setUserInput(e.target.value);
                    }}
                    onKeyUp={pressEnter}
                />
            </div>
            <div style={{ margin: '10px 0' }}>
                <div style={{ fontSize: '0.95em', fontWeight: 'bold' }}>Contraseña:</div>
                <Input.Password size="large" prefix={<LockOutlined />} 
                    value={loginHook.passwordInput}
                    onChange={(e) => {
                        loginHook.setPasswordInput(e.target.value);
                    }}
                    onKeyUp={pressEnter}
                />
            </div>
            {
                loginHook.loading ?
                <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                :
                <Button
                    type="primary"
                    style={{ width: '100%' }}
                    onClick={loginHook.login}
                >
                    Ingresar
                </Button>
            }
            <RestorePassword />
        </Card>
    )
}