import React from "react";
import { Table } from "react-bootstrap";
import { findAllComponentEvent } from "../../_events/component/find.event";
import { findAllDependencyEvent } from "../../_events/dependency/find.event";

export default function ImplementationActionQuestion({ question, changeQuestions = (q) => {} }) {

    const [ actions, setActions ] = React.useState([]);
    const [ depedencies, setDepedencies ] = React.useState([]);

    React.useEffect(() => {
        findAllComponentEvent()
        .then(json => {
            setActions(json.list);
        })
        .catch(err => {
            alert(err.message);
        });

        findAllDependencyEvent()
        .then(json => {
            setDepedencies(json.list);
        })
        .catch(err => {
            alert(err.message);
        });

    }, []);


    const renderCheck = (action, dep) => {
        return(
            <div className="flex-col flex-center" style={{ width: '100%' }}>
                <input 
                    checked = {question.results ? question.results.findIndex(r => r.component === action.cod && r.dependency === dep.cod ) !== -1 ? true : false : false }
                    onChange={(e) => {
                        const checked = e.target.checked;
                        let newQuestion = {...question};
                        if(! newQuestion.results) newQuestion.results = [];
                        if(checked) newQuestion.results.push({component: action.cod, dependency: dep.cod});
                        else {
                            let index = newQuestion.results.findIndex(r => r.component === action.cod && r.dependency === dep.cod );
                            if(index !== -1){
                                newQuestion.results.splice(index, 1);
                            }
                        }
                        changeQuestions(newQuestion);
                    }}
                    type="checkbox"
                />
            </div>
        );
    }
    
    return(
        <Table responsive>
            <thead>
                <tr>
                    <th style={{ fontSize: '0.8em' }}>
                    Dependencias
                    </th>
                    {
                        actions.map((dep, key) => (
                            <th style={{ fontSize: '0.8em' }} key={`title_${key}`}>
                                {dep.name}
                            </th>
                        ))
                    }
                </tr>                
            </thead>
            <tbody>
            {
                depedencies.map((action, key) => {
                    return(
                        <tr key={`action_${key}`}>
                            <td style={{ fontSize: '0.8em' }}>{action.name}</td>  
                            {
                                actions.map((dep, key) => (
                                    <th key={`dep_${key}`}>
                                        {renderCheck(action, dep)}
                                    </th>
                                ))
                            }   
                        </tr>               
                    );
                })
            }
            </tbody>
        </Table>      
    );
}