export const MONTHS = [
    'Todos',
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
];

export const buildDate = (date_created) => {
    let d = new Date(date_created);
    return `${d.getFullYear()}-${(d.getMonth() + 1).toString().length === 1 ? `0${(d.getMonth() + 1).toString()}` : d.getMonth() + 1}-${d.getDate().toString().length === 1 ? `0${d.getDate().toString()}` : d.getDate()}`
};