export const validateRequireQuestions = (question) => {
    const { type, results } = question;
    let res = false;
    if(type === 'yng' || question.cod === 'P8' || type === 'scala' || type === 'count' || type === 'ynm'){
        let answeredCounter = 0;
        for (let i = 0; i < results.length; i++) {
            if(results[i].value !== '' && results[i].value !== null) answeredCounter += 1;            
        }
        res = answeredCounter === results.length;
    } else if(type === 'uniq'){
        let answeredCounter = 0;
        for (let i = 0; i < results.length; i++) {
            if(results[i].answer !== '' && results[i].answer !== undefined  && results[i].answer !== null) answeredCounter += 1;            
        }
        res = answeredCounter >= 1;
    } else if(type === 'opegt'){
        let answeredCounter = 0;
        for (let i = 0; i < results.length; i++) {
            if(results[i].value !== '' && results[i].value !== null && results[i].value?.split("|").length > 1) {
                let parts = results[i].value?.split("|");
                if(parts[0] !== '' && parts[0] !== null && parts[1] !== '' && parts[1] !== null) answeredCounter += 1;
            }            
        }
        res = answeredCounter >= 1;
    } else if(type === 'open' || type === 'openg'){
        let answeredCounter = 0;
        for (let i = 0; i < results.length; i++) {
            if(results[i].value !== '' && results[i].value !== null && results[i].value !== undefined) answeredCounter += 1;            
        }
        res = answeredCounter >= 1;
    }
    return res;
}

export const buildNextIndex = (idx, question) => {
    const { cod } = question;
    if(cod === 'P2'){
        if(question.results[0]?.answer === 'P22') idx += 1
    } else if(cod === 'R1'){
        if(question.results[0]?.answer === 'R12') idx += 5
    } else if(cod === 'P4'){
        if(question.results[0]?.answer === 'P42') idx += 3
    } else if(cod === 'P7'){
        if(question.results[0]?.answer === 'P72') idx += 1
    } else if(cod === 'Cu4'){
        if(question.results[0]?.answer === 'Cu42') idx += 1
    } else if(cod === 'Cu10'){
        if(question.results[0]?.answer === 'Cu102') idx += 1
    } else if(cod === 'Ef5'){
        if(question.results[0]?.answer === 'Ef52') idx += 1
    }
    return idx;
}