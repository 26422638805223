import React from 'react';
import { getToken } from '../_utils/storage_handler';
import { Link } from 'react-router-dom';
import { init_path } from '../pages/path_pages';

const AuthWrapper = (AuthComponent) => (
    class extends React.Component{
        render(){
            if(validateSession()){
                return(<AuthComponent {...this.props} />);
            } else {
                return(
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '20px 20px' }}>
                        <span>¡Ingreso no autorizado!</span>
                        <span>Debes diligenciar tus datos primero</span>
                        <Link to={init_path.full_path}>
                            Ir a menú principal
                        </Link>
                    </div>
                );
            }
        }
    }
);

const validateSession = () => {
    let token = getToken();
    return token !== null;
}

export default AuthWrapper;