import React from "react";
import { Form } from "react-bootstrap";

export default function OpenAnswer({ question, changeQuestions = (q) => {} }) {
    
    return(
        <div>
            {
                question.answers?.map( (answer, key) => {
                    if(answer.type === 'open') return <div key={`unique_${key}`} style={{ margin: '10px 0' }}>{
                        <Form.Control 
                            value={question.results ? question.results.find(r => r.answer === answer.cod ) ? question.results.find(r => r.answer === answer.cod ).open_answer : '' : '' }
                            onChange={(e) => {
                                let newQuestion = {...question};
                                newQuestion.results = [];
                                newQuestion.results.push({answer: answer.cod, open_answer: e.target.value});
                                changeQuestions(newQuestion);
                            }}
                            type="text"
                        />
                    }</div>;
                    else return <div key={`unique_${key}`}>Not implemented</div>
                })
            }            
        </div>        
    );
}