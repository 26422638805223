import { Col, Input } from "antd";

export default function Openg({ question, changeResult }){

    return(
        <Col lg={24} xs={24}>
            <table style={{ width: '100%' }}>
            <tbody style={{ width: '100%' }}>
            {
                question?.answers.map((a, key) => {
                    let value = '';
                    let indexValue = question?.results.findIndex(r => r.answer === a.cod);
                    if(indexValue !== -1) value = question?.results[indexValue].value;
                    return(
                        <tr key={`yng_tr_${key}`} style={{ width: '100%' }}>
                            <td style={{ width: '4%', borderBottom: '1px solid' }}>
                                {key + 1}
                            </td>
                            <td style={{ width: '96%', borderBottom: '1px solid' }}>
                                <span style={{ fontSize: '0.8em', fontWeight: 'bold' }}>{a.label}</span>
                                <Input
                                    value={ value }
                                    onChange={(e) => {
                                        changeResult(question?.type, a.cod, e.target.value ? e.target.value.toUpperCase() : e.target.value);
                                    }}
                                />
                            </td>
                        </tr>
                    );
                })
            }
            </tbody>
            </table>            
        </Col>
    );
}