import {
    findAllDependency,
    findDependencyById,
    findAllDependencyPaged,
} from '../../_services/dependency.service';

export const findAllDependencyEvent = async () => {
    return await findAllDependency();
}

export const findDependencyByIdEvent = async (id) => {
    return await findDependencyById(id);
}

export const findAllDependencyPagedEvent = async (page, limit = 8) => {
    return await findAllDependencyPaged(page, limit);
}



/** Generated by https://github.com/VictorAndres20 code generator for database, NestJS, React */