import { Button, Checkbox, Modal, message } from "antd";
import { useState } from "react";
import { downloadTermsPdf } from "../../_services/resource.service";
import { downloadFile } from "../../_utils/download_file.util";

export default function TermConditionModal({ isModalOpen, handleOk, handleCancel, setAcceptTerms }) {
    
    const [ acceptTerms1, setAcceptTerms1 ] = useState(false); 
    const [ acceptTerms2, setAcceptTerms2 ] = useState(false);
    const [ acceptTerms1Show, setAcceptTerms1Show ] = useState(false); 
    const [ acceptTerms2Show, setAcceptTerms2Show ] = useState(false);

    const changeTerms = (value, state) => {
        if( value && state ) {
            setAcceptTerms(true);
            handleCancel();
        } else if(! value){
            setAcceptTerms(false);
        }
    }

    const downloadTermPdf = (type, callback) => {
        downloadTermsPdf(type)
        .then(json => {
            downloadFile(json.data.bytes, json.data.path);
            callback();
        })
        .catch(err => {
            message.error(err.message);
        });
    }
    
    return (
        <>
        <Modal title="Tratamiento de datos" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
            footer={[]}
        >
            <p style={{ color: '#000', fontSize: '0.9em' }}>Este es un espacio de construcción de comunidad de aprendizaje interactivo para las instituciones de Educación Superior del país,  que  permite compartir y reconocer las experiencias significativas e innovadoras en torno a la implementación del Modelo de Gestión de la Permanencia y la Graduación Estudiantil propuesto por el MEN,  y sus estrategia de visibilización.</p>
            <p style={{ color: '#000', fontSize: '0.9em' }}>Cada  IES, podrá registrar las experiencias destacadas que considere, antes del 28 de enero de 2024.</p>
            <div style={{ marginBottom: '10px', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Button
                    type="link"
                    onClick={() => {
                        downloadTermPdf('1', () => {
                            setAcceptTerms1Show(true);
                        })
                    }}
                >
                    CONDICIONES DE USO
                </Button>
                {
                    acceptTerms1Show &&
                    <Checkbox
                        checked={acceptTerms1}
                        onChange={(e) => {
                            setAcceptTerms1(e.target.checked);
                            changeTerms(e.target.checked, acceptTerms2);
                        }}
                    >Acepto condiciones</Checkbox>
                }
                <Button
                    type="link"
                    onClick={() => {
                        downloadTermPdf('2', () => {
                            setAcceptTerms2Show(true);
                        })
                    }}
                >
                    TRATAMIENTO DE DATOS
                </Button>
                {
                    acceptTerms2Show &&
                    <Checkbox
                        checked={acceptTerms2}
                        onChange={(e) => {
                            setAcceptTerms2(e.target.checked);
                            changeTerms(e.target.checked, acceptTerms1);
                        }}
                    >Autorizo</Checkbox>
                }
            </div>
            <p style={{ color: '#000', fontSize: '0.9em' }}><strong>IMPORTANTE:</strong> Recuerde leer detenidamente las condiciones de uso y diligenciar todos los campos de acuerdo a la experiencia significativa</p>
        </Modal>
        </>
    );
};