import { Col, Row } from "antd";
import AHeader from "../../widgets /headers/a_header";
import { Button, Card } from "react-bootstrap";
import { useReportAResult } from "../../_hooks/a_result/useReportAResult.hook";

const pass = "Ascun2024**";

export default function ASurveyReport(){

    const downloadHook = useReportAResult();

    return(
        <Row>
            <Col lg={24} xs={24}>
                <Row>
                    <Col lg={24} xs={24}>
                    <AHeader />
                    </Col>
                </Row>
            </Col>
            <Col lg={24} xs={24}>
                <div className="flex-col flex-center">
                    <Card>
                        <Card.Body>
                            <div className="flex-col flex-center">
                                <span style={{ margin: '10px 0', fontSize: '1.1em' }}>Descargar el reporte Excel aquí</span>
                                {
                                    downloadHook.loading ?
                                    <div className="vp-spinner"></div>
                                    :
                                    <Button onClick={() => {
                                        let password = prompt("Contraseña");
                                        if(password === pass){
                                            downloadHook.downloadExcel();
                                        } else {
                                            alert("Contraseña incorrecta");
                                        }
                                    }} variant="success">
                                        Descargar con códigos
                                    </Button>
                                }
                            </div>
                            <div className="flex-col flex-center">
                                <span style={{ margin: '10px 0', fontSize: '1.1em' }}>Descargar el reporte Excel 2 aquí</span>
                                {
                                    downloadHook.loading ?
                                    <div className="vp-spinner"></div>
                                    :
                                    <Button onClick={() => {
                                        let password = prompt("Contraseña");
                                        if(password === pass){
                                            downloadHook.downloadExcelNames();
                                        } else {
                                            alert("Contraseña incorrecta");
                                        }
                                    }} variant="success">
                                        Descargar con nombres
                                    </Button>
                                }
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </Col>
        </Row>
    );
}