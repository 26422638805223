import { useState } from "react";
import { Row, Col, Divider, Input, Button, Checkbox, Spin, Result, Modal } from 'antd';
import { useInitializeSurvey } from "../../_hooks/a_survey/useInitializeSurvey.hook";
import SelectAIes from "../../widgets /selects/a_ies_select";

export default function StartASurveyForm() {

    const hook = useInitializeSurvey();
    const [ authorize, setAuthorize ] = useState(false);

    return (
        <Col id="form" lg={20} xs={24}>
            <Modal title="La encuesta ya existe" open={hook.showErrorObj.open} onOk={hook.closeErrorObj} onCancel={hook.closeErrorObj}>
                <Result
                    status="warning"
                    title={
                        <div style={{ fontSize: '0.9em', fontWeight: 'bold' }}>
                            {hook.showErrorObj.message}
                        </div>
                    }
                    extra={
                    <></>
                    }
                />
            </Modal>
            <Row>
                <Col lg={24} xs={24}>
                    <Divider />
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <span style={{ fontSize: '1.9em', fontWeight: 'bold', textAlign: 'center' }}>CARACTERÍSTICAS INSTITUCIONALES</span>
                    </div>
                </Col>
                <Col lg={12} xs={24}>
                    <div style={{ marginTop: '15px', marginLeft: '8px', fontSize: '0.85em', fontWeight: 'bold' }}>Nombre y apellidos</div>
                    <Input
                        value={ hook.data.person_name }
                        onChange={(e) => {
                            hook.changeProp('person_name', e.target.value);
                        }}
                    />
                </Col>
                <Col lg={12} xs={24}>
                    <div style={{ marginTop: '15px', marginLeft: '8px', fontSize: '0.85em', fontWeight: 'bold' }}>Correo electrónico</div>
                    <Input
                        value={ hook.data.person_email }
                        onChange={(e) => {
                            hook.changeProp('person_email', e.target.value);
                        }}
                    />
                </Col>
                <Col lg={12} xs={24}>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ marginTop: '15px', marginLeft: '8px', fontSize: '0.85em', fontWeight: 'bold' }}>Institución de Educación Superior:</div>
                    <SelectAIes
                        allowClear={true}
                        value={ hook.data.ies }
                        setValue={(e) => {
                            hook.changeProp('ies', e);
                        }}
                    />
                    </div>
                </Col>
                <Col lg={12} xs={24}>
                    <div style={{ marginTop: '15px', marginLeft: '8px', fontSize: '0.85em', fontWeight: 'bold' }}>Cargo:</div>
                    <Input
                        value={ hook.data.person_job }
                        onChange={(e) => {
                            hook.changeProp('person_job', e.target.value);
                        }}
                    />
                </Col>
                <Col lg={24} xs={24}>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ marginTop: '15px', marginLeft: '8px', fontSize: '0.85em', fontWeight: 'bold' }}>Autorización de tratamiento de datos:</div>
                        <Checkbox
                            checked={authorize}
                            onChange={(e) => {
                                setAuthorize(e.target.checked);
                            }}
                        >
                            Autorizo
                        </Checkbox>
                    </div>
                </Col>
                <Col lg={2}>
                </Col>
                <Col lg={20}>
                    <div style={{ margin: '10px 0', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        {
                            authorize &&
                            (hook.loading ?
                            <Spin />
                            :
                            <Button
                                onClick={hook.initialize}
                                type="primary"
                            >
                                Iniciar encuesta
                            </Button>)
                        }
                    </div>
                </Col>
                <Col lg={2}>
                </Col>
            </Row>
        </Col>

    );
}