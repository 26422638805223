import { loginUser } from "../../_services/user.service"
import { putIesId, putIsAdmin, putIsRoot, putToken, putUserId } from "../../_utils/storage_handler";

export const loginEvent = async (body)  => {
    const { login, password } = body;
    if(login === '' || login === null) throw new Error('Correo/Usuario vacío');
    if(password === '' || password === null) throw new Error('Contraseña vacía');
    let json = await loginUser(body);
    const admin = json.data.admin;
    const root = json.data.root;
    putToken(json.data.token);
    putUserId(json.data.uuid);
    putIsAdmin(admin);
    putIsRoot(root);
    putIesId(json.data.ies);
    return {
        admin, root
    };
}