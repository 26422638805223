import {
    findAllAgroup,
    findAgroupById,
    findAllAgroupPaged,
} from '../../_services/a_group.service';

export const findAllAgroupEvent = async () => {
    return await findAllAgroup();
}

export const findAgroupByIdEvent = async (id) => {
    return await findAgroupById(id);
}

export const findAllAgroupPagedEvent = async (page, limit = 8) => {
    return await findAllAgroupPaged(page, limit);
}



/** Generated by https://github.com/VictorAndres20 code generator for database, NestJS, React */