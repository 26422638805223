import AuthWrapper from "../hoc/auth_wrapper";
import AdminWrapper from "../hoc/admin_wrapper";
import StartASurveyPage from "./a_start_survey";
import ASurveyPage from "./a_survey";
import InitPage from "./init";
import AdminTemplate from "./admin/admin_template";
import ProfilePage from "./admin/profile";
import UploadsPage from "./admin/uploads";
import FormUploadsPage from "./admin/uploads/form";
import UsersIesPage from "./admin/users";
import HomeTemplate from "./home_template";
import LoginPage from "./login";
import { 
    init_path,
    survey_path,
    report_path,
    start_survey_path,
    start_a_survey_path,
    a_survey_path,
    app_template_path,
    home_template_path, 
    resource_path, 
    uploads_path, 
    profile_path, 
    login_path, 
    upload_form_path,
    register_path,
    resource_category_path,
    resource_atention_path,
    users_ies_path,
    a_survey_report,
} from "./path_pages";
import ReportPage from "./report";
import StartSurveyPage from "./start_survey";
import SurveyPage from "./survey";
import RegisterPage from "./register";
import ResourcePage from "./resource";
import ResourceAtentionPage from "./resource/resource_atention";
import ResourceCategoryPage from "./resource/resource_category";
import ASurveyReport from "./a_survey_report";

export const router_pages = [
    { 
        path: `${init_path.path}`, 
        component: InitPage, 
    },
    { 
        path: `${start_survey_path.path}`, 
        component: StartSurveyPage, 
    }, 
    { 
        path: `${survey_path.path}`, 
        component: SurveyPage, 
    }, 
    { 
        path: `${report_path.path}`, 
        component: ReportPage, 
    },  
    { 
        path: `${start_a_survey_path.path}`, 
        component: StartASurveyPage, 
    },  
    { 
        path: `${a_survey_path.path}`, 
        component: AuthWrapper(ASurveyPage), 
    },  
    { 
        path: `${a_survey_report.path}`, 
        component: ASurveyReport, 
    },   
    // Repo
    { 
        path: `${home_template_path.path}`, 
        component: HomeTemplate,
        children: [
            { 
                path: `${login_path.path}`, 
                component: LoginPage, 
            },
            { 
                path: `${register_path.path}`, 
                component: RegisterPage, 
            },
        ], 
    },
    { 
        path: `${app_template_path.path}`, 
        component: AuthWrapper(AdminTemplate),
        children: [
            {
                path: `${resource_path.path}`, 
                component: ResourcePage,
            },
            {
                path: `${resource_category_path.path}`, 
                component: ResourceCategoryPage,
            },
            {
                path: `${resource_atention_path.path}`, 
                component: ResourceAtentionPage,
            },
            {
                path: `${uploads_path.path}`, 
                component: AdminWrapper(UploadsPage),
            },
            {
                path: `${upload_form_path.path}`, 
                component: AdminWrapper(FormUploadsPage),
            },
            {
                path: `${users_ies_path.path}`, 
                component: AdminWrapper(UsersIesPage),
            },
            {
                path: `${profile_path.path}`, 
                component: ProfilePage,
            },
        ], 
    },
];