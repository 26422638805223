import {
    findAllAies,
    findAiesById,
    findAllAiesPaged,
} from '../../_services/a_ies.service';

export const findAllAiesEvent = async () => {
    return await findAllAies();
}

export const findAiesByIdEvent = async (id) => {
    return await findAiesById(id);
}

export const findAllAiesPagedEvent = async (page, limit = 8) => {
    return await findAllAiesPaged(page, limit);
}



/** Generated by https://github.com/VictorAndres20 code generator for database, NestJS, React */