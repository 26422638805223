import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { cleanValues, getAquestions, getAsurvey } from "../../_utils/storage_handler";
import { start_a_survey_path } from "../../pages/path_pages";
import { createTransactionAsurveyEvent } from "../../_events/a_survey/create.event";
import { message } from "antd";
import { validateAsurveyByEmailIesEvent } from "../../_events/a_survey/find.event";

export const useCreateAresult = () => {

    const [ loading, setLoading ] = useState(false);
    const [ minutesTaken, setMinutesTaken ] = useState(0);
    const navigate = useNavigate();

    const sendResults = () => {
        let survey = getAsurvey();
        survey.minutes_taken = minutesTaken;
        survey.results = [];
        const questions = getAquestions();
        for (let i = 0; i < questions.length; i++) {
            const group = questions[i];
            for (let j = 0; j < group.questions.length; j++) {
                const question = group.questions[j];
                question.results = question.results.sort( (a, b) => a.answer < b.answer ? -1 : 0 );
                for (let k = 0; k < question.results.length; k++) {
                    const result = question.results[k];
                    survey.results.push(result);
                }
            }
        }
        setLoading(true);
        validateAsurveyByEmailIesEvent(survey.person_email, survey.ies)
        .then(json => {            
            createTransactionAsurveyEvent(survey)
            .then(json => {
                message.success('Enuesta enviada');
                cleanValues();
                navigate(start_a_survey_path.full_path);
            })
            .catch(err => {
                message.error(err.message);
                setLoading(false);
            });
        })
        .catch(err => {
            setLoading(false);
            if(("" + err.message).startsWith('Ya existe')){
                message.success(`Gracias por tu tiempo. ${err.message}`);
                cleanValues();
                navigate(start_a_survey_path.full_path);
            } else {
                message.error(err.message);
            }            
        });
    }

    useEffect(() => {
        let survey = getAsurvey();
        setMinutesTaken(Math.floor((new Date() - new Date(survey.date_created)) / 60000));
    }, []);

    return {
        loading, minutesTaken, sendResults
    }
}