import {
    createAsurvey,
    editAsurvey,
    createTransactionAsurvey
} from '../../_services/a_survey.service';
import { validateAsurvey } from './model';

export const createAsurveyEvent = async (body) => {
    validateAsurvey(body);
    return await createAsurvey(body);
}

export const editAsurveyEvent = async (id, body) => {
    validateAsurvey(body);
    return await editAsurvey(id, body);
}

export const createTransactionAsurveyEvent = async (body) => {
    validateAsurvey(body);
    return await createTransactionAsurvey(body);
}



/** Generated by https://github.com/VictorAndres20 code generator for database, NestJS, React */