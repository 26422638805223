import { Button, Col, Divider, Row } from "antd";
import Answers from "./answers";

export default function Survey({ question, nextQuestion, backQuestion, changeResult }){

    return(
        <Row>
            <Col lg={24} xs={24}>
                <Divider />
                <div className="flex-col flex-center">
                    <span style={{ fontSize: '1.1em', fontWeight: 'bold' }}>{question?.component}</span>
                </div>
                <div className="flex-row" style={{ margin: '20px 0' }}>
                    <span style={{ fontSize: '1.1em', fontWeight: 'bold' }}>{question?.index}</span>
                    <span style={{ fontSize: '1.1em', marginLeft: '20px' }}>{question?.label}</span>
                </div>
            </Col>
            <Col lg={24} xs={24}>
            <Answers 
                question={question}
                changeResult={changeResult}
            />
            </Col>
            <Col lg={24} xs={24}>
            <div className="flex-row" style={{ width: '100%', marginBottom: '40px' }}>
                <div style={{ width: '50%', paddingLeft: '20px', display: 'flex' }}>
                    <Button
                        style={{ width: '300px' }}
                        onClick={() => {
                            backQuestion();
                        }}
                    >
                        Atrás
                    </Button>
                </div>
                <div style={{ width: '50%', paddingRight: '20px', display: 'flex', flexDirection: 'row-reverse' }}>
                    <Button
                        style={{ width: '300px' }}
                        type="primary"
                        onClick={() => {
                            nextQuestion();
                        }}
                    >
                        Siguiente
                    </Button>
                </div>
            </div>
            </Col>
        </Row>
    );
}