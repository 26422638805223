import { getIesId } from '../_utils/storage_handler.js';
import { handleFetch, GET_OPTIONS, POST_OPTIONS, PUT_OPTIONS } from './base.service.js';


const BASE_PATH = '/user';

export const findAllUser = async () => {
    return await handleFetch(`${BASE_PATH}/all`, GET_OPTIONS);
}

export const findUserById = async (id) => {
    return await handleFetch(`${BASE_PATH}/id/${id}`, GET_OPTIONS);
}

export const loginUser = async (body) => {
    return await handleFetch(`${BASE_PATH}/login`, POST_OPTIONS, body);
}

export const createUser = async (body) => {
    return await handleFetch(`${BASE_PATH}/create`, POST_OPTIONS, body);
}

export const createAdminUser = async (body) => {
    return await handleFetch(`${BASE_PATH}/create-admin`, POST_OPTIONS, body);
}

export const createNormalUser = async (body) => {
    return await handleFetch(`${BASE_PATH}/create-normal`, POST_OPTIONS, body);
}

export const editUser = async (id, body) => {
    return await handleFetch(`${BASE_PATH}/edit/${id}`, PUT_OPTIONS, body);
}

export const findAllUserPaged = async (page, limit) => {
    return await handleFetch(`${BASE_PATH}/all-paged/${page}/${limit}`, GET_OPTIONS);
}

export const findAllUserByIesPaged = async (page, limit) => {
    return await handleFetch(`${BASE_PATH}/all-paged/ies/${getIesId()}/${page}/${limit}`, GET_OPTIONS);
}

export const changeAdminUser = async (body) => {
    return await handleFetch(`${BASE_PATH}/change-admin`, PUT_OPTIONS, body);
}

export const changeUserPassword = async (body) => {
    return await handleFetch(`${BASE_PATH}/change-password`, PUT_OPTIONS, body);
}



/** Generated by https://github.com/VictorAndres20 code generator for database, NestJS, React */