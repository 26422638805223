import { useState } from "react";
import { getUserId } from "../../_utils/storage_handler";
import { createResourceEvent } from "../../_events/resource/create.event";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { uploads_path } from "../../pages/path_pages";

export const useCreateResource = () => {
    
    const navigate = useNavigate();

    const [ loading, setLoading ] = useState(false);

    const [ type, setType ] = useState('');
    const [ focusPeople, setFocusPeople ] = useState('');
    const [ implementationTime, setImplementationTime ] = useState('');
    const [ keywords, setKeywords ] = useState([]);
    const [ author1, setAuthor1 ] = useState('');
    const [ job1, setJob1 ] = useState('');
    const [ author2, setAuthor2 ] = useState('');
    const [ job2, setJob2 ] = useState('');
    const [ author3, setAuthor3 ] = useState('');
    const [ job3, setJob3 ] = useState('');
    const [ name, setName ] = useState('');
    const [ description, setDescription ] = useState('');
    const [ format, setFormat ] = useState('');
    
    const [ path, setPath ] = useState('');

    const create = () => {
        const body = {
            label: type,
            implementation_time: implementationTime,
            focus_people: focusPeople,
            keywords,
            author1: author1.trim(),
            job1: job1.trim(),
            author2: author2.trim(),
            job2: job2.trim(),
            author3: author3.trim(),
            job3: job3.trim(),            
            name: name.trim(),
            description,
            path,
            type: format,
            user_upload: getUserId(),
        };
        setLoading(true);
        createResourceEvent(body)
        .then(json => {
            setLoading(false);
            message.success('Creado con éxito');
            navigate(uploads_path.full_path);
        })
        .catch(err => {
            setLoading(false);
            message.error(err.message);
        });        
    }
    
    return {
        name, description, loading, setImplementationTime, setFocusPeople, setKeywords, setAuthor1, setJob1, setAuthor2, setJob2, setAuthor3, setJob3, setName, setDescription, setFormat, setType, setPath, create, path,
    };
}