export const validateIesRegistered = (body) => {
    const { ies, ies_type, ies_nature, city } = body;
    if(ies === '' || ies === null) throw new Error('Institución de Educación Superior vacía');
    if(ies_nature === '' || ies_nature === null) throw new Error('Naturaleza de Institución de Educación Superior vacía');
    if(ies_type === '' || ies_type === null) throw new Error('Tipo de Institución de Educación Superior vacía');
    if(city === '' || city === null) throw new Error('Ciudad de Institución de Educación Superior vacía');
}

export const transformEntityIesRegistered = (entity) => {
    let newEnt = {...entity}
    newEnt.ies = newEnt.ies?.uuid;
    newEnt.ies_type = newEnt.ies_type?.uuid;
    newEnt.ies_nature = newEnt.ies_nature?.uuid;
    newEnt.city = newEnt.city?.cod;
    return newEnt;
}

export const buildEmptyIesRegistered = () => {
    return {
        date_created: new Date(),
        campus: '',
        ies: '',
        ies_type: '',
        ies_nature: '',
        city: '',
        dep: '',
    };
}



/** Generated by https://github.com/VictorAndres20 code generator for database, NestJS, React */