import React from "react";
import { Button, Col, Container, ProgressBar, Row } from "react-bootstrap";
import { getQuestions } from "../../_utils/storage_handler";
import UniqueQuestion from "./unique_question";
import ImplementationActionQuestion from "./implementation_action_answer";
import AlternativeQuestion from "./alternatives";
import ScaleQuestion from "./scale";
import OpenAnswer from "./open_answer";
import ResultModal from "./modal_results";

export default function Questions() {

    const questionsSaved = getQuestions();

    const [ questions, setQuestions ] = React.useState(questionsSaved);
    const [ indexQ, setIndexQ ] = React.useState(0);
    const [ progress, setProgress ] = React.useState(1);
    const [ question, setQuestion ] = React.useState(questionsSaved[0]);

    const renderAnswer = () => {
        if(question.type === 'unique') return(<UniqueQuestion question={question} changeQuestions={(q) => changeQuestions(q)} />);
        else if(question.type === 'altern') return (<AlternativeQuestion question={question} changeQuestions={(q) => changeQuestions(q)} />);
        else if(question.type === 'scale_c' || question.type === 'scale_a') return (<ScaleQuestion question={question} changeQuestions={(q) => changeQuestions(q)} type = {question.type} />);
        else if(question.type === 'comp') return (<ImplementationActionQuestion question={question} changeQuestions={(q) => changeQuestions(q)} />);
        else if(question.type === 'open') return (<OpenAnswer question={question} changeQuestions={(q) => changeQuestions(q)} />);
        else return(<>Not implemented {question.type}</>)
    }

    const changeQuestions = (newQuestion) => {
        let newQuestions = [...questions];
        newQuestions[indexQ] = newQuestion;
        setQuestions(newQuestions);
        setQuestion(newQuestion);
    }

    const changeQuestion = (index) => {
        setProgress(Math.round((index + 1) * 100 / questions.length));
        setIndexQ(index);
        setQuestion(questions[index]);
    }

    return(
        <Container style={{ marginTop: '40px' }}>            
            <Row>
                <Col lg={12}>
                    <div className="flex-col flex-center" style={{ width: '100%', marginBottom: '40px' }}>
                        <ProgressBar style={{ width: '100%' }} now={progress} label={`${progress}%`} />
                    </div>
                </Col>
                <Col lg={12}>
                    {
                        question &&
                        <div>
                            <div>{question.label}</div>
                            {renderAnswer()}
                            <div className="flex-row flex-center" style={{ width: '100%' }}>
                                <div className="flex-col flex-center" style={{ width: '50%' }}>
                                    {
                                        indexQ > 0 && 
                                        <Button
                                            onClick={() => {
                                                changeQuestion(indexQ -1);
                                            }}
                                        >
                                            Atras
                                        </Button>
                                    }
                                </div>
                                <div className="flex-col flex-center" style={{ width: '50%' }}>
                                    {
                                        indexQ < questionsSaved.length - 1 &&
                                        <Button
                                            onClick={() => {
                                                changeQuestion(indexQ + 1);
                                            }}
                                        >
                                            Siguiente
                                        </Button>
                                    }
                                    {
                                        indexQ === questionsSaved.length - 1 &&
                                        <ResultModal
                                            questions={questions}
                                        />
                                    }
                                </div>
                            </div>
                        </div>                        
                    }
                </Col>
            </Row>
        </Container>
        
    );
}