export const validateAresult = (body) => {
    //const {  } = body;
}

export const transformEntityAresult = (entity) => {
    let newEnt = {...entity}
    newEnt.answer = newEnt.answer?.cod;
    newEnt.survey = newEnt.survey?.uuid;
    return newEnt;
}

export const buildEmptyAresult = () => {
    return {
        value: '',
        addition_value: '',
        answer: '',
        survey: '',
    };
}



/** Generated by https://github.com/VictorAndres20 code generator for database, NestJS, React */