import {
    findAllLabelType,
    findLabelTypeById,
    findAllLabelTypePaged,
} from '../../_services/label_type.service';

export const findAllLabelTypeEvent = async () => {
    return await findAllLabelType();
}

export const findLabelTypeByIdEvent = async (id) => {
    return await findLabelTypeById(id);
}

export const findAllLabelTypePagedEvent = async (page, limit = 8) => {
    return await findAllLabelTypePaged(page, limit);
}



/** Generated by https://github.com/VictorAndres20 code generator for database, NestJS, React */