import { Button, Col, Input, Row, Spin } from "antd";
import { useUpdateUser } from "../../../_hooks/user/useUpdateUserHook";

export default function Form(){

    const hook = useUpdateUser();

    return(
        <Row style={{ padding: '0 20px' }} gutter={6}>
            <Col lg={24}>
                <Row gutter={6}>
                    <Col className="gutter-row" lg={8} xs={24}>
                        <div style={{ display: 'flex', flexDirection: 'column', margin: '14px 0' }}>
                            <span style={{ fontSize: '0.85em', fontWeight: 'bold', marginLeft: '10px' }}>Nombres y apellidos completos:</span>
                            <Input placeholder="Nombres y apellidos"
                                value={hook.data.full_name}
                                onChange={(e) => {
                                    hook.changeProperty(`full_name`, e.target.value);
                                }}
                            />
                        </div>
                    </Col>
                    <Col className="gutter-row" lg={16} xs={24}>
                    </Col>
                    <Col className="gutter-row" lg={8} xs={24}>
                        <div style={{ display: 'flex', flexDirection: 'column', margin: '14px 0' }}>
                            <span style={{ fontSize: '0.85em', fontWeight: 'bold', marginLeft: '10px' }}>Correo institucional:</span>
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                <Input
                                    style={{ width: '50%', borderRight: 'none', textAlign: 'end' }}
                                    value={hook.data.email.split("@")[0]}
                                    onChange={(e) => {
                                        hook.changeProperty(`email`, `${e.target.value}@${hook.data.email.split("@")[1]}`);
                                    }}
                                />
                                <Input
                                    style={{ width: '50%' }}
                                    addonBefore={<>@</>}
                                    value={hook.data.email.split("@")[1]}
                                    onChange={(e) => {
                                        hook.changeProperty(`email`, `${hook.data.email.split("@")[0]}@${e.target.value}`);
                                    }}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col className="gutter-row" lg={16} xs={24}>
                    </Col>
                    <Col className="gutter-row" lg={8} xs={24}>
                        <div style={{ display: 'flex', flexDirection: 'column', margin: '14px 0' }}>
                            <span style={{ fontSize: '0.85em', fontWeight: 'bold', marginLeft: '10px' }}>Teléfono/Contacto:</span>
                            <Input
                                maxLength={10}
                                type="number"
                                style={{ width: '100%' }}
                                value={hook.data.phone}
                                onChange={(e) => {
                                    hook.changeProperty(`phone`, e.target.value);
                                }}
                            />
                        </div>                
                    </Col>
                    <Col className="gutter-row" lg={16} xs={24}>
                    </Col>
                    <Col className="gutter-row" lg={8} xs={24}>
                        <span style={{ fontSize: '0.85em', fontWeight: 'bold', marginLeft: '10px' }}>Contraseña:</span>
                        <Input.Password
                            style={{ width: '100%' }}
                            value={hook.data.password}
                            onChange={(e) => {
                                hook.changeProperty(`password`, e.target.value);
                            }}
                        />
                    </Col>
                    <Col className="gutter-row" lg={16} xs={24}>
                    </Col>
                    <Col style={{ marginTop: '20px' }} className="gutter-row" lg={8} xs={24}>
                        {
                            hook.loading ? 
                            <Spin />
                            :
                            <Button
                                style={{ width: '100%' }}
                                type="primary"
                                onClick={() => {
                                    hook.update();
                                }}
                            >
                                Actualizar
                            </Button>
                        }
                    </Col>
                    <Col className="gutter-row" lg={16} xs={24}>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}