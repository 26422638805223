import React from "react";
import MainHeader from "../../widgets /headers/main_header";
import Report from "./report";

export default function ReportPage() {

    return(
        <div>
            <MainHeader />
            <Report />
        </div>
        
    );
}