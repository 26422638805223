import React, { useState } from 'react';
import DataTable from '../../../widgets /tables/data_table';
import { useResourceTable } from '../../../_hooks/resource/useResourceTable.hook';
import { downloadResourceByPath, removeResourceByPath } from '../../../_services/resource.service';
import { Spin, message } from 'antd';
import { downloadBytesInfoFile } from '../../../_utils/download_file.util';
import PaginationData from '../../../widgets /pagination/pagination';
import { buildDate } from '../../../_utils/month_labels';
import ConfirmButton from '../../../widgets /buttons/confirm_button';

const count = 6;

export default function Table(){

    const [ loading, setLoading ] = useState(false);
    const dataHook = useResourceTable(0, count);

    const donwload = (path, name) => {
      setLoading(true);
      downloadResourceByPath(path)
      .then(json => {
        setLoading(false);
        downloadBytesInfoFile(json.data.path, name);
      })
      .catch(err => {
        setLoading(false);
        message.error(err.message);
      });
    }

    const remove = (path) => {
      setLoading(true);
      removeResourceByPath(path)
      .then(json => {
        setLoading(false);
        dataHook.loadData(dataHook.actualPage, count);
      })
      .catch(err => {
        setLoading(false);
        message.error(err.message);
      });
    }

    const columns = [
        {
          title: 'Fecha cargue',
          dataIndex: 'date_created',
          width: '10%',
          render: (text, param, index) => (
              <span key={`date_${index}`} style={{ fontSize: '0.8em' }}>{buildDate(param.date_created)}</span>
            )
        },
        {
          title: 'IES',
          dataIndex: 'user_upload',
          width: '20%',
          render: (text, param, index) => (
              <span key={`ies_${index}`} style={{ fontSize: '0.8em' }}>{`${param.user_upload?.ies?.ies?.name} ${param.user_upload?.ies?.campus ? `SEDE ${param.user_upload?.ies?.campus}` : ''}`}</span>
            )
        },
        {
        title: 'Estrategia visualización',
        dataIndex: 'name',
        width: '10%',
        render: (text, param, index) => (
          <span key={`name_${index}`} style={{ fontSize: '0.75em' }}>{param.name}</span>
        )
        },
        {
        title: 'Contextualización',
        dataIndex: 'description',
        width: '15%',
        render: (text, param, index) => (
          <span key={`desc_${index}`} style={{ fontSize: '0.75em' }}>{param.description}</span>
        )
        },
        {
          title: 'Formato',
          dataIndex: 'type',
          width: '15%',
          render: (text, param, index) => (
              <span key={`type_${index}`} style={{ fontSize: '0.8em' }}>{param.type.name}</span>
            )
        },
        {
          title: 'Tipo',
          dataIndex: 'label',
          width: '20%',
          render: (text, param, index) => (
              <span key={`label_${index}`} style={{ fontSize: '0.8em' }}>{param.label.name}</span>
            )
        },
        {
          title: 'Acciones',
          dataIndex: 'uuid',
          width: '10%',
          render: (text, param, index) => loading ? (<Spin key={`spin_${index}`} />) :  (
              <div key={`action_${index}`} style={{ display: 'flex', flexDirection: 'row' }}>
                <span
                onClick={() => {
                  donwload(param.path, `${param.user_upload?.ies?.ies?.uuid}_${param.type ? param.type.cod : 'FILE'}`)
                }} 
                className='navbar-icon bi-cloud-download-fill' style={{ cursor: 'pointer' }}>
              </span>
              <ConfirmButton
                title='Eliminar recurso'
                description='¿Estas seguro/a de eliminar el recurso?'
                confirmFunc={() => remove(param.path)}
              >
                <span
                  className='navbar-icon bi-trash-fill' style={{ cursor: 'pointer' }}>
                </span>
              </ConfirmButton>
              </div>
            )
        },
    ];
    return(
      <div>
        <PaginationData
          changePage={(page) => {
            dataHook.setActualPage(page - 1);
          }}
          actualPage={dataHook.actualPage}
          totalData={dataHook.data[1]}
          count={count}
        />
        <DataTable columns={columns} data={dataHook.data[0]} />
        <PaginationData
          changePage={(page) => {
            dataHook.setActualPage(page - 1);
          }}
          actualPage={dataHook.actualPage}
          totalData={dataHook.data[1]}
          count={count}
        />
      </div>
    );
};