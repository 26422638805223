import { useEffect, useState } from "react";
import { findIesRegisteredByIdEvent } from "../../../_events/ies_registered/find.event";
import { getIesId } from "../../../_utils/storage_handler";
import { message } from "antd";

export default function IesInfo(){

    const [ ies, setIes ] = useState(null);

    useEffect(() => {
        findIesRegisteredByIdEvent(getIesId())
        .then(json => {
            setIes(json.data);
        })
        .catch(err => {
            message.error(err.message);
        });
    }, []);

    return(
        <div style={{ marginLeft: '19px' }}>
            <span style={{ fontSize: '0.75em' }}>{ies?.ies?.name}{ies?.campus ? ` SEDE ${ies?.campus}` : ''}</span>
        </div>
    );
}