import { useState } from "react"
import { changeUserPassword } from "../../_services/user.service";
import { message } from "antd";

export const useChangePassword = () => {
    const [ email, setEmail ] = useState('');
    const [ loading, setLoading ] = useState(false);

    const changePassword = () => {
        setLoading(true);
        changeUserPassword({ email })
        .then(json => {
            setLoading(false);
            message.success(`Se ha enviado un correo a ${email} con los nuevos datos de acceso`);
        })
        .catch(err => {
            setLoading(false);
            message.error(err.message);
        })
    }

    return{
        email, changePassword, setEmail, loading
    }
}