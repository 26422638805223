import React, { useState } from 'react';
import DataTable from '../../../widgets /tables/data_table';
import { Spin, message } from 'antd';
import PaginationData from '../../../widgets /pagination/pagination';
import { buildDate } from '../../../_utils/month_labels';
import { useUserIesTable } from '../../../_hooks/user/useUserIesTable';
import { getUserId } from '../../../_utils/storage_handler';
import { changeAdminUserEvent } from '../../../_events/user/create.event';

const count = 6;

export default function Table(){

    const userId = getUserId();

    const [ loading, setLoading ] = useState(false);
    const dataHook = useUserIesTable(0, count);

    const setAdmin = (user) => {
        setLoading(true);
        changeAdminUserEvent({
            uuid: user.uuid,
            admin: user.admin === 1 ? 0 : 1,
        })
        .then(json => {
            setLoading(false);
            dataHook.loadData(dataHook.actualPage, count);
        })
        .catch(err => {
            setLoading(false);
            message.error(err.message);
        })
    }

    const columns = [
        {
          title: 'Fecha registro',
          dataIndex: 'date_created',
          width: '10%',
          render: (text, param, index) => (
              <span key={`date_${index}`} style={{ fontSize: '0.8em' }}>{buildDate(param.date_created)}</span>
            )
        },
        {
          title: 'IES',
          dataIndex: 'ies',
          width: '20%',
          render: (text, param, index) => (
              <span key={`ies_${index}`} style={{ fontSize: '0.8em' }}>{`${param.ies?.ies?.name} ${param.ies?.campus ? `SEDE ${param.ies?.campus}` : ''}`}</span>
            )
        },
        {
            title: 'Nombre',
            dataIndex: 'name',
            width: '10%',
            render: (text, param, index) => (
            <span key={`name_${index}`} style={{ fontSize: '0.75em' }}>{param.full_name}</span>
            )
        },
        {
            title: 'Correo',
            dataIndex: 'email',
            width: '10%',
            render: (text, param, index) => (
              <span key={`name_${index}`} style={{ fontSize: '0.75em' }}>{param.email}</span>
            )
        },
        {
            title: 'Admin',
            dataIndex: 'admin',
            width: '10%',
            render: (text, param, index) => (
              <span key={`name_${index}`} style={{ fontSize: '0.75em' }}>{param.admin === 1 ? 'Sí' : 'No'}</span>
            )
        },
        {
          title: 'Dar administración',
          dataIndex: 'uuid',
          width: '10%',
          render: (text, param, index) => loading ? (<Spin key={`spin_${index}`} />) 
                : param.uuid !== userId ? (
                <div key={`action_${index}`} style={{ display: 'flex', flexDirection: 'row' }}>
                    {
                        param.admin !== 1 ?
                        <span
                            onClick={() => {
                                setAdmin(param);
                            }}
                            className='navbar-icon bi-person-check-fill' style={{ cursor: 'pointer' }}>
                        </span>
                        :
                        <span
                            onClick={() => {
                                setAdmin(param);
                            }}
                            className='navbar-icon bi-person-x-fill' style={{ cursor: 'pointer' }}>
                        </span>
                    }
                </div>
            ) : <></>
        },
    ];
    return(
      <div>
        <PaginationData
          changePage={(page) => {
            dataHook.setActualPage(page - 1);
          }}
          actualPage={dataHook.actualPage}
          totalData={dataHook.data[1]}
          count={count}
        />
        <DataTable columns={columns} data={dataHook.data[0]} />
        <PaginationData
          changePage={(page) => {
            dataHook.setActualPage(page - 1);
          }}
          actualPage={dataHook.actualPage}
          totalData={dataHook.data[1]}
          count={count}
        />
      </div>
    );
};