import { useState } from "react";
import { downloadExcelFile } from "../../_utils/download_file.util";
import { downloadExcelAresultEvent, downloadExcelAresultNamesEvent } from "../../_events/a_result/find.event";

export const useReportAResult = () => {

    const [ loading, setLoading ] = useState(false);

    const downloadExcel = () => {
        setLoading(true);
        downloadExcelAresultEvent()
        .then(json => {
            downloadExcelFile(json.data.bytes, `reporte_encuesta_profundidad_${new Date()}`);
            setLoading(false);
        })
        .catch(err => {
            alert(err.message);
            setLoading(false);
        });
    }

    const downloadExcelNames = () => {
        setLoading(true);
        downloadExcelAresultNamesEvent()
        .then(json => {
            downloadExcelFile(json.data.bytes, `reporte_encuesta_profundidad_2_${new Date()}`);
            setLoading(false);
        })
        .catch(err => {
            alert(err.message);
            setLoading(false);
        });
    }

    return {
        loading,
        downloadExcel,
        downloadExcelNames
    };
}