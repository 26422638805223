import { Col, Row } from "antd";
import { useResourceAtention } from "../../_hooks/resource/useResourceAtention.hook";
import Info from "./info";
import SelectImplementationTime from "./select_implementation_time";
import SelectFocusPeople from "./select_focus_people";
import SelectKeywords from "./select_keywords";

const count = 6

export default function ResourceAtentionPage(){

    const dataHook = useResourceAtention(0, count);  

    return(
        <section style={{ marginTop: '-80px' }} className="explore-section section-padding" id="section_2">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center" >
                        <h2 className="mb-4">Experiencias y recursos</h2>
                        <h6 className="mb-4">Búsqueda por atención</h6>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <Row>
                    <Col lg={6} xs={24}>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            <span style={{ fontSize: '0.85em', fontWeight: 'bold', marginLeft: '10px' }}>Filtrar tiempo de implementación:</span>
                            <div style={{ margin: '20px 20px', display: 'flex', flexDirection: 'column' }}>
                                <SelectImplementationTime                                
                                    allowClear={true}
                                    value={dataHook.filter}
                                    setValue={(value) => {
                                        if(value === undefined) dataHook.setFilter('');
                                        else dataHook.setFilter(value);
                                    }}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col lg={6} xs={24}>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            <span style={{ fontSize: '0.85em', fontWeight: 'bold', marginLeft: '10px' }}>Filtrar población focalizada:</span>
                            <div style={{ margin: '20px 20px', display: 'flex', flexDirection: 'column' }}>
                                <SelectFocusPeople                              
                                    allowClear={true}
                                    value={dataHook.filter}
                                    setValue={(value) => {
                                        if(value === undefined) dataHook.setFilter('');
                                        else dataHook.setFilter(value);
                                    }}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col lg={6} xs={24}>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            <span style={{ fontSize: '0.85em', fontWeight: 'bold', marginLeft: '10px' }}>Filtrar palabras claves:</span>
                            <div style={{ margin: '20px 20px', display: 'flex', flexDirection: 'column' }}>
                                <SelectKeywords                           
                                    allowClear={true}
                                    value={dataHook.filter}
                                    setValue={(value) => {
                                        if(value === undefined) dataHook.setFilter('');
                                        else dataHook.setFilter(value);
                                    }}
                                />
                            </div>
                        </div>
                    </Col>
                    
                </Row>
                
                
            </div>
            
            <Info dataHook={dataHook} count={count} />
        </section>
    );
}