import React, { useEffect, useState } from 'react';
import { Select, message } from 'antd';
import { findAllIesRepoTypeEvent } from '../../_events/ies_repo_type/find.event';
import { filterOption } from '../../_utils/filter_select';

export default function SelectIesType({value, setValue = () => {}, allowClear = false }) {

    const [ data, setData ] = useState([]);

    useEffect(() => {
        findAllIesRepoTypeEvent()
        .then(json => {
            setData(json.list);
        })
        .catch(err => {
            message.error(err.message);
        })
    }, []);

    const onChange = (value) => {
        setValue(value)
    };

    const buildValue = (val) => {
        if(val === undefined || val === null) return '';
        const found = data.find( d => d.uuid === val );
        if(found) return { value: found.uuid, label: found.name };
        else return '';
    }  

    return(
        <Select
            allowClear={allowClear}
            showSearch
            placeholder="Selecciona tipo"
            optionFilterProp="children"
            value={ buildValue(value) }
            onChange={onChange}
            filterOption={filterOption}
            options={data.map( d => ({ value: d.uuid, label: d.name }) )}
        />
    );
};