import { useEffect, useState } from "react";
import { message } from "antd";
import { findAllResourceOwnerPaged } from "../../_services/resource.service";

export const useResourceTable = (page, count = 10) => {

    const [actualPage, setActualPage] = useState(page);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const loadData = (p, c) => {
        setLoading(true);
        findAllResourceOwnerPaged(p, c)
        .then(json => {
            setData(json.paged);
            setLoading(false);
        })
        .catch(err => {
            setLoading(false);
            message.error(err.message);
        });
    }

    useEffect(() => {
        loadData(actualPage, count);
    }, [actualPage, count]);

    return {
        actualPage, setActualPage, loading, data, loadData
    };
}