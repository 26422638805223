import { useState } from "react";
import { buildExcelPersonEvent } from "../../_events/person/find.event";
import { downloadExcelFile } from "../../_utils/download_file.util";

export const useExcelPerson = () => {

    const [ loading, setLoading ] = useState(false);

    const downloadExcel = () => {
        setLoading(true);
        buildExcelPersonEvent()
        .then(json => {
            downloadExcelFile(json.data.bytes, `reporte_encuesta_ascun_${new Date()}`);
            setLoading(false);
        })
        .catch(err => {
            alert(err.message);
            setLoading(false);
        });
    }

    return {
        loading,
        downloadExcel,
    };
}