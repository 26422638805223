import { Button, Col, Divider, Row, Spin } from "antd";
import { useCreateAresult } from "../../_hooks/a_result/useCreateAresult.hook";

export default function Finalize({ backQuestion }){

    const hook = useCreateAresult();

    return(
        <Row>
            <Col lg={24} xs={24}>
                <Divider />
                <div className="flex-col flex-center">
                    <span style={{ fontSize: '1.7em', fontWeight: 'bold' }}>¡Casi listo! Por favor, envía la encuesta dando click en 'Enviar encuesta'</span>
                    <span style={{ fontSize: '1.7em', fontWeight: 'bold' }}>Tiempo: {hook.minutesTaken} minutos</span>
                </div>
            </Col>
            <Col lg={24} xs={24}>
                <div className="flex-col flex-center" style={{ width: '100%' }}>
                    {
                        hook.loading ?
                        <Spin />
                        :
                        <Button
                            style={{ width: '300px', margin: '20px' }}
                            type="primary"
                            onClick={ hook.sendResults }
                        >
                            Enviar encuesta
                        </Button>
                    }
                    <Button
                        style={{ width: '300px' }}
                        onClick={() => {
                            backQuestion();
                        }}
                    >
                        Atrás
                    </Button>
                </div>
            </Col>
            <Col lg={24} xs={24}>
                <Divider />
                <div className="flex-col flex-center">
                    <span style={{ fontSize: '1.1em', fontWeight: 'bold' }}>Gracias por su tiempo, esta información es muy valiosa para nosotros.</span>
                </div>
            </Col>
        </Row>
    );
}