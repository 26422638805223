import React, { useEffect, useState } from 'react';
import { Select, message } from 'antd';
import { findAllDepartmentEvent } from '../../_events/department/find.event';
import { filterOption } from '../../_utils/filter_select';
import { findAllCityByDepEvent } from '../../_events/city/find.event';

export default function SelectCityDepartment({ valueCity, valueDepartment, setValueCity = () => {}, setValueDepartment = () => {}, allowClear = false }) {

    const [ data1, setData1 ] = useState([]);
    const [ data2, setData2 ] = useState([]);

    const loadCities = (dep) => {
        findAllCityByDepEvent(dep)
        .then(json => {
            setData2(json.list);
        })
        .catch(err => {
            message.error(err.message);
        });
    }

    useEffect(() => {
        findAllDepartmentEvent()
        .then(json => {
            setData1(json.list);
        })
        .catch(err => {
            message.error(err.message);
        });
    }, []);

    const buildValue = (data, val) => {
        if(val === undefined || val === null) return '';
        const found = data.find( d => d.cod === val );
        if(found) return { value: found.cod, label: `${found.name}` };
        else return '';
    }

    return(
        <div style={{ width: '100%', display: 'flex',flexDirection: 'row' }}>
            <Select
                style={{ width: '50%' }}
                allowClear={allowClear}
                showSearch
                placeholder="Selecciona departamento"
                optionFilterProp="children"
                onChange={(e) => {
                    setValueDepartment(e ? e : '');
                    if(e) loadCities(e);
                    else setData2([]);
                }}
                filterOption={filterOption}
                value={ buildValue(data1, valueDepartment) }
                options={data1.map( d => ({ value: d.cod, label: `${d.name}` }) )}
            />
            {
                data2.length > 0 &&
                <Select
                    style={{ width: '50%' }}
                    allowClear={allowClear}
                    showSearch
                    placeholder="Selecciona municipio/ciudad"
                    optionFilterProp="children"
                    onChange={(e) => {
                        setValueCity(e);
                    }}
                    filterOption={filterOption}
                    value={ buildValue(data2, valueCity) }
                    options={data2.map( d => ({ value: d.cod, label: `${d.name}` }) )}
                />
            }
        </div>
    );
};