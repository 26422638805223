import {
    findAllIesRepoType,
    findIesRepoTypeById,
    findAllIesRepoTypePaged,
} from '../../_services/ies_repo_type.service';

export const findAllIesRepoTypeEvent = async () => {
    return await findAllIesRepoType();
}

export const findIesRepoTypeByIdEvent = async (id) => {
    return await findIesRepoTypeById(id);
}

export const findAllIesRepoTypePagedEvent = async (page, limit = 8) => {
    return await findAllIesRepoTypePaged(page, limit);
}



/** Generated by https://github.com/VictorAndres20 code generator for database, NestJS, React */