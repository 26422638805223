import React, { useEffect, useState } from 'react';
import { Select, message } from 'antd';
import { findAllKeywordEvent } from '../../../_events/keyword/find.event';
import { filterOption } from './filter_util';

export default function SelectKewords({ setValue = () => {} }) {

    const [ data, setData ] = useState([]);

    useEffect(() => {
        findAllKeywordEvent()
        .then(json => {
            setData(json.list);
        })
        .catch(err => {
            message.error(err.message);
        })
    }, []);

    const onChange = (value) => {
        setValue(value)
    };

    return(
        <Select
            mode="multiple"
            showSearch
            placeholder="Selecciona palabras clave"
            optionFilterProp="children"
            onChange={onChange}
            filterOption={filterOption}
            options={data.map( d => ({ value: d.cod, label: d.name }) )}
        />
    );
};