import { useEffect, useState } from "react";
import { buildEmptyAsurvey, validateAsurvey } from "../../_events/a_survey/model";
import { message } from 'antd';
import { useNavigate } from "react-router-dom";
import { a_survey_path } from "../../pages/path_pages";
import { cleanValues, putAGroupIdx, putAQuestionIdx, putAquestions, putAsurvey, putToken } from "../../_utils/storage_handler";
import { findAllAgroupEvent } from "../../_events/a_group/find.event";
import { buildEmptyAresult } from "../../_events/a_result/model";
import { validateAsurveyByEmailIesEvent } from "../../_events/a_survey/find.event";

export const useInitializeSurvey = () => {

    const [ data, setData ] = useState(buildEmptyAsurvey());
    const [ loading, setLoading ] = useState(false);
    const [ showErrorObj, setShowErrorObj ] = useState({ open: false, message: '' });
    const navigate = useNavigate();

    const changeErrorObj = (open, message) => {
        setShowErrorObj({open, message});
    }

    const closeErrorObj = () => {
        changeErrorObj(false, '');
    }

    const changeProp = (key, value) => {
        let newData = {...data};
        newData[`${key}`] = value;
        setData(newData);
    }

    const buildWithResults = (questions) => {
        let newQuestions = [...questions];
        for (let i = 0; i < newQuestions.length; i++) {
            let group = {...newQuestions[i]};
            for (let j = 0; j < group.questions.length; j++) {
                let question = {...group.questions[j]};
                delete question.groups;
                if(question.type === 'uniq'){
                    question.results = [{...buildEmptyAresult(), value: '', answer: question.answers[0].cod}];
                } else {
                    question.results = [];
                    for (let k = 0; k < question.answers.length; k++) {
                        const answer = question.answers[k];
                        question.results.push({
                            answer: answer.cod,
                            value: question.type === 'opegt' ? '|' : '',
                        });
                    }
                }
                group.questions[j] = question;
            }
            newQuestions[i] = group;
        }
        return newQuestions;
    }
    
    const initialize = () => {
        try{
            validateAsurvey(data);
            setLoading(true);
            validateAsurveyByEmailIesEvent(data.person_email, data.ies)
            .then(json => {
                findAllAgroupEvent()
                .then(json => {
                    putToken('123');
                    data.date_created = new Date();
                    putAsurvey(data);
                    putAquestions(buildWithResults(json.list));
                    putAQuestionIdx(-1);
                    putAGroupIdx(0);
                    navigate(a_survey_path.full_path);
                    setLoading(false);
                })
                .catch(err => {
                    setLoading(false);
                    message.error(err.message);
                });
            })
            .catch(err => {
                setLoading(false);
                changeErrorObj(true, err.message);
            });            
        } catch(err){
            message.error(err.message);
        }
    }

    useEffect(() => {
        cleanValues();
    }, []);

    return {
        data, loading, changeProp, initialize, showErrorObj, closeErrorObj
    };
}