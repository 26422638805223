import {
    findAllScalable,
    findScalableById,
    findAllScalablePaged,
    findAllScalableByType,
} from '../../_services/scalable.service';

export const findAllScalableEvent = async () => {
    return await findAllScalable();
}

export const findScalableByIdEvent = async (id) => {
    return await findScalableById(id);
}

export const findAllScalableByTypeEvent = async (type) => {
    return await findAllScalableByType(type);
}

export const findAllScalablePagedEvent = async (page, limit = 8) => {
    return await findAllScalablePaged(page, limit);
}



/** Generated by https://github.com/VictorAndres20 code generator for database, NestJS, React */