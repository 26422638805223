import { Button, Col, Divider, Row } from "antd";
import { groups } from "../../_utils/groups";

export default function Presentation({ group, index, changeIndex, backIndex }){

    return(
        <Row>
            <Col lg={3}>
            </Col>
            <Col lg={18} xs={24}>
                <Divider />
                <div className="flex-col flex-center" style={{ width: '100%' }}>
                    <span style={{ fontSize: '1.6em', fontWeight: 'bold' }}>{group?.label}</span>
                    <span style={{ fontSize: '1.1em', margin: '10px 0' }}>{groups[index].desc}</span>
                    <span style={{ fontSize: '1.1em', margin: '10px 0' }}>{groups[index].desc2}</span>
                    <Button
                        style={{ margin: '10px 0' }}
                        type="primary"
                        onClick={() => {
                            changeIndex();
                        }}
                    >
                        Continuar
                    </Button>
                    {
                        index > 0 &&
                        <Button
                            onClick={() => {
                                backIndex();
                            }}
                        >
                            Atrás
                        </Button>
                    }
                </div>
            </Col>
            <Col lg={3}>
            </Col>
        </Row>
    );
}