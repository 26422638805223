export const init_path = {
    path: '',
    full_path: `/`,
}

export const start_survey_path = {
    path: 'init-survey',
    full_path: `/init-survey`,
}

export const survey_path = {
    path: 'survey',
    full_path: `/survey`,
}

export const report_path = {
    path: 'report',
    full_path: `/report`,
}

export const start_a_survey_path = {
    path: 'iniciar-encuesta-profundidad',
    full_path: `/iniciar-encuesta-profundidad`,
}

export const a_survey_path = {
    path: 'encuesta-profundidad',
    full_path: `/encuesta-profundidad`,
}

export const a_survey_report = {
    path: 'encuesta-profundidad-reporte',
    full_path: `/encuesta-profundidad-reporte`,
}

// Repo paths
export const home_template_path = {
    path: 'repositorio',
    full_path: `/repositorio`,
}
export const login_path = {
    path: '',
    full_path: `${home_template_path.full_path}/`,
}
export const register_path = {
    path: 'register',
    full_path: `${home_template_path.full_path}/register`,
}
export const app_template_path = {
    path: 'app-repositorio',
    full_path: `/app-repositorio`,
}
export const resource_path = {
    path: 'resources',
    full_path: `${app_template_path.full_path}/resources`,
}
export const resource_category_path = {
    path: 'resources-category',
    full_path: `${app_template_path.full_path}/resources-category`,
}
export const resource_atention_path = {
    path: 'resources-atention',
    full_path: `${app_template_path.full_path}/resources-atention`,
}
export const uploads_path = {
    path: 'uploads',
    full_path: `${app_template_path.full_path}/uploads`,
}
export const upload_form_path = {
    path: 'upload-form',
    full_path: `${app_template_path.full_path}/upload-form`,
}
export const users_ies_path = {
    path: 'users-ies',
    full_path: `${app_template_path.full_path}/users-ies`,
}
export const profile_path = {
    path: 'profile',
    full_path: `${app_template_path.full_path}/profile`,
}
