import { Link } from "react-router-dom";
import { uploads_path } from "../../path_pages";
import { Button, Col, Input, Row, Spin } from "antd";
import TextArea from "antd/es/input/TextArea";
import UploadInput from "./upload_input";
import { useCreateResource } from "../../../_hooks/resource/useCreateResource.hook";
import SelectTypes from "./select_type";
import SelectLabel from "./select_label";
import SelectFocusPeople from "./select_focus_people";
import SelectImplementationTime from "./select_implementation_time";
import SelectKewords from "./select_keywords";
export default function FormUploadsPage(){

    const createHook = useCreateResource();

    return(
        <div>
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px', }}>
                <div style={{ width: '30%' }}>
                    <Link style={{ marginLeft: '20px' }} className="btn custom-btn custom-border-btn" to={uploads_path.full_path}>
                        Atras
                    </Link>                 
                </div>
                <div style={{ width: '70%', display: 'flex', flexDirection: 'column' }}>
                    <h6>Cargar nueva experiencia</h6>
                </div>
            </div>
            <hr />
            <div style={{ margin: '10px 10px' }}>
                <Row gutter={6}>
                    <Col lg={8} xs={24}>
                        <div style={{ display: 'flex', flexDirection: 'column', margin: '14px 0' }}>
                            <span style={{ fontSize: '0.85em', fontWeight: 'bold', marginLeft: '10px' }}>Categoría:</span>
                            <SelectLabel 
                                setValue={createHook.setType}
                            />
                        </div>
                    </Col>
                    <Col lg={8} xs={24}>
                        <div style={{ display: 'flex', flexDirection: 'column', margin: '14px 0' }}>
                            <span style={{ fontSize: '0.85em', fontWeight: 'bold', marginLeft: '10px' }}>Tiempo de implementación:</span>
                            <SelectImplementationTime 
                                setValue={createHook.setImplementationTime}
                            />
                        </div>
                    </Col>
                    <Col lg={8} xs={24}>
                        <div style={{ display: 'flex', flexDirection: 'column', margin: '14px 0' }}>
                            <span style={{ fontSize: '0.85em', fontWeight: 'bold', marginLeft: '10px' }}>Población focalizada:</span>
                            <SelectFocusPeople 
                                setValue={createHook.setFocusPeople}
                            />
                        </div>
                    </Col>
                    <Col lg={24} xs={24}>
                        <div style={{ display: 'flex', flexDirection: 'column', margin: '14px 0' }}>
                            <span style={{ fontSize: '0.85em', fontWeight: 'bold', marginLeft: '10px' }}>Palabras clave:</span>
                            <SelectKewords 
                                setValue={createHook.setKeywords}
                            />
                        </div>
                    </Col>
                </Row>
                <Row gutter={6}>
                    <Col className="gutter-row" lg={12} xs={24}> 
                        <div style={{ display: 'flex', flexDirection: 'column', margin: '14px 0' }}>
                            <span style={{ fontSize: '0.85em', fontWeight: 'bold', marginLeft: '10px' }}>Contextualización experiencia:</span>
                            <span style={{ fontSize: '0.75em', marginLeft: '10px', fontStyle: 'italic' }}>Presentación de la experiencia exitosas para la permanencia desde su Impacto, Lecciones aprendidas, logros obtenidos, entre otros.  máximo 300 caracteres</span>
                            <span style={createHook.description.length > 300 ? { color: 'red', marginLeft: '10px', fontSize: '0.8em' }:{marginLeft: '10px', fontSize: '0.8em'}}>{createHook.description.length} máximo 300</span>
                            <TextArea rows={4} 
                                onChange={(e) => {
                                    createHook.setDescription(e.target.value);
                                }}
                            />
                            
                        </div>                       
                        <div style={{ display: 'flex', flexDirection: 'column', margin: '14px 0' }}>
                            <span style={{ fontSize: '0.85em', fontWeight: 'bold', marginLeft: '10px' }}>Estrategia de visibilización y sostenibilidad:</span>
                            <span style={{ fontSize: '0.75em', marginLeft: '10px', fontStyle: 'italic' }}>Definición breve de cómo la experiencia, permite o proyecta la visión de la IES. máximo 300 caracteres</span>
                            <span style={createHook.name.length > 300 ? { color: 'red', marginLeft: '10px', fontSize: '0.8em' }:{marginLeft: '10px', fontSize: '0.8em'}}>{createHook.name.length} máximo 300</span>
                            <TextArea rows={4} 
                                onChange={(e) => {
                                    createHook.setName(e.target.value);
                                }}
                            />
                        </div>                        
                    </Col>
                    <Col className="gutter-row" lg={12} xs={24}>
                        <div style={{ display: 'flex', flexDirection: 'column', margin: '14px 0' }}>
                            <span style={{ fontSize: '0.85em', fontWeight: 'bold', marginLeft: '10px' }}>Autores de la experiencia:</span>
                            <Row>
                                <Col lg={12} xs={24}>
                                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: '5px' }}>
                                        <span style={{ fontSize: '0.85em', fontWeight: 'bold', marginLeft: '10px' }}>Nombre completo:</span>
                                        <Input 
                                            placeholder="Nombre completo autor 1"
                                            onChange={(e) => {
                                                createHook.setAuthor1(e.target.value);
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col lg={12} xs={24}>
                                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: '5px' }}>
                                        <span style={{ fontSize: '0.85em', fontWeight: 'bold', marginLeft: '10px' }}>Rol/Cargo:</span>
                                        <Input 
                                            placeholder="Rol/Cargo autor 1"
                                            onChange={(e) => {
                                                createHook.setJob1(e.target.value);
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12} xs={24}>
                                    <Input 
                                        placeholder="Nombre completo autor 2"
                                        onChange={(e) => {
                                            createHook.setAuthor2(e.target.value);
                                        }}
                                    />
                                </Col>
                                <Col lg={12} xs={24}>
                                    <Input 
                                        placeholder="Rol/Cargo autor 2"
                                        onChange={(e) => {
                                            createHook.setJob2(e.target.value);
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12} xs={24}>
                                    <Input 
                                        placeholder="Nombre completo autor 3"
                                        onChange={(e) => {
                                            createHook.setAuthor3(e.target.value);
                                        }}
                                    />
                                </Col>
                                <Col lg={12} xs={24}>
                                    <Input 
                                        placeholder="Rol/Cargo autor 3"
                                        onChange={(e) => {
                                            createHook.setJob3(e.target.value);
                                        }}
                                    />
                                </Col>
                            </Row>
                        </div>
                        
                        <div style={{ display: 'flex', flexDirection: 'column', margin: '14px 0' }}>
                            <span style={{ fontSize: '0.85em', fontWeight: 'bold', marginLeft: '10px' }}>Formato:</span>
                            <SelectTypes
                                setValue={(value) => {
                                    createHook.setFormat(value)
                                }}
                            />
                        </div>                        
                        <div style={{ display: 'flex', flexDirection: 'column', margin: '14px 0' }}>
                            <span style={{ fontSize: '0.85em', fontWeight: 'bold', marginLeft: '10px' }}>Archivo:</span>
                            <UploadInput 
                                value={createHook.path}
                                setValue={(url) => {
                                    createHook.setPath(url);
                                }}
                            />
                        </div>                        
                    </Col>
                    <Col className="gutter-row" lg={24} xs={24}>
                    <div style={{ display: 'flex', flexDirection: 'column', margin: '14px 0' }}>
                            {
                                createHook.loading ?
                                <Spin />
                                :
                                <Button 
                                    onClick={createHook.create}
                                    type="primary">
                                    Cargar
                                </Button>
                            }
                        </div>
                    </Col>
                </Row>  
                
            </div>
        </div>
    );
}