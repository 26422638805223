import {
    findAllIesNaturaly,
    findIesNaturalyById,
    findAllIesNaturalyPaged,
} from '../../_services/ies_naturaly.service';

export const findAllIesNaturalyEvent = async () => {
    return await findAllIesNaturaly();
}

export const findIesNaturalyByIdEvent = async (id) => {
    return await findIesNaturalyById(id);
}

export const findAllIesNaturalyPagedEvent = async (page, limit = 8) => {
    return await findAllIesNaturalyPaged(page, limit);
}



/** Generated by https://github.com/VictorAndres20 code generator for database, NestJS, React */