import { Pagination } from "antd";

export default function PaginationData({ actualPage, count = 10, totalData, changePage = (p) => {console.log(p)} }){
    return(
        <Pagination
            size="small" 
            pageSize={count}
            current={actualPage + 1}
            total={totalData}
            onChange={changePage}
        />
    );
}