import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Card } from 'antd';
import { Link } from "react-router-dom";
import { start_a_survey_path, start_survey_path, login_path } from "../path_pages";
import AHeader from "../../widgets /headers/a_header";

export default function InitPage() {

    return(
        <div>
            <AHeader />
            <Container style={{ padding: '55px 0', backgroundColor: '#eee' }} >       
                <Row>
                    <Col lg={6}>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <Card title="Encuesta 1" extra={<Link to={start_survey_path.full_path}>Ir a encuesta</Link>} style={{ width: 300 }}>
                                <div>IDENTIFICACIÓN DE ACCIONES DE PERMANENCIA Y GRADUACIÓN EN LAS IES</div>
                            </Card>
                        </div>
                    </Col> 
                    <Col lg={6}>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <Card title="Encuesta 2" extra={<Link to={start_a_survey_path.full_path}>Ir a encuesta</Link>} style={{ width: 300 }}>
                                <div>Encuesta análisis a profundidad de la implementación de la guía e identificación de capacidades institucionales para la permanencia y graduación estudiantil</div>
                            </Card>
                        </div>
                    </Col>   
                    <Col lg={3}></Col>
                    <Col lg={6}>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <Card title="Repositorio" extra={<Link to={login_path.full_path}>Ir a repositorio</Link>} style={{ width: 300 }}>
                                <div>Repositorio Banco de Experiencias significativas</div>
                            </Card>
                        </div>
                    </Col>    
                    <Col lg={3}></Col>                
                </Row>
            </Container>
        </div>
        
    );
}