import React from "react";
import { Table } from "react-bootstrap";
import { findAllScalableByTypeEvent } from "../../_events/scalable/find.event";

const scales = [
    {cod: '1', name: '1'},
    {cod: '2', name: '2'},
    {cod: '3', name: '3'},
    {cod: '4', name: '4'},
]

export default function ScaleQuestion({ question, changeQuestions = (q) => {}, type }) {

    const [ scalables, setScalables ] = React.useState([]);

    React.useEffect(() => {
        if(type === 'scale_c'){
            findAllScalableByTypeEvent('C.')
            .then(json => {
                setScalables(json.list);
            })
            .catch(err => {
                alert(err.message);
            });
        } else if(type === 'scale_a'){
            findAllScalableByTypeEvent('A.')
            .then(json => {
                setScalables(json.list);
            })
            .catch(err => {
                alert(err.message);
            });
        }
        

    }, [type]);


    const renderCheck = (alter, state) => {
        return(
            <div className="flex-col flex-center" style={{ width: '100%' }}>
                <input 
                    checked = {question.scalation ? question.scalation.findIndex(r => r.scalable === alter.cod && r.scale === state.cod ) !== -1 ? true : false : false }
                    onChange={(e) => {
                        let newQuestion = {...question};
                        if(! newQuestion.scalation) newQuestion.scalation = [];
                        let indexA = newQuestion.scalation.findIndex( a => a.scalable === alter.cod );
                        if(indexA !== -1){
                            newQuestion.scalation[indexA].scale = state.cod;
                        } else {
                            newQuestion.scalation.push({scalable: alter.cod, scale: state.cod});
                        }
                        changeQuestions(newQuestion);
                    }}
                    type="radio"
                />
            </div>
        );
    }
    
    return(
        <Table responsive>
            <thead>
                <tr>
                    <th style={{ fontSize: '0.8em' }}>
                    { type === 'scale_c' ? 'Componente' : type === 'scale_a' ? 'Aspecto' : '' }
                    </th>
                    {
                        scales.map((dep, key) => (
                            <th style={{ fontSize: '0.8em' }} key={`title_${key}`}>
                                {dep.name}
                            </th>
                        ))
                    }
                </tr>                
            </thead>
            <tbody>
            {
                scalables.map((action, key) => {
                    return(
                        <tr key={`action_${key}`}>
                            <td style={{ fontSize: '0.8em' }}>{action.name}</td>  
                            {
                                scales.map((dep, key) => (
                                    <th key={`dep_${key}`}>
                                        {renderCheck(action, dep)}
                                    </th>
                                ))
                            }   
                        </tr>               
                    );
                })
            }
            </tbody>
        </Table>      
    );
}