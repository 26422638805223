import IesInfo from "../uploads/ies_info";
import Table from "./table";

export default function UsersIesPage(){
    return(
        <div>
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px', }}>
                <div style={{ width: '60%' }}>
                    <h6 style={{ marginLeft: '20px' }}>Usuarios de IES</h6>
                </div>
            </div>
            <IesInfo />
            <hr />
            <Table />
        </div>
    );
}