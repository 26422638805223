import { Row, Col, Spin, message } from 'antd';
import AHeader from '../../widgets /headers/a_header';
import { getAGroupIdx, getAQuestionIdx, getAsurvey } from '../../_utils/storage_handler';
import { useState } from 'react';
import Presentation from './presentation';
import Survey from './survey';
import { putAGroupIdx, putAQuestionIdx } from "../../_utils/storage_handler";
import Finalize from './finalize';
import { useAresult } from '../../_hooks/a_result/useAresult.hook';
import { buildNextIndex, validateRequireQuestions } from '../../_utils/validate_require_questions';

export default function ASurveyPage() {

    const survey = getAsurvey();
    const { questions, changeResult } = useAresult();
    const [ questionIndex, setQuestionIndex ] = useState(getAQuestionIdx());
    const [ groupIndex, setGroupIndex ] = useState(getAGroupIdx());

    const changeQuestion = (idx, isBack = false) => {
        try{
            const nextIdx = isBack ? idx : buildNextIndex(idx, questions[groupIndex].questions[questionIndex]);
            if(nextIdx >= questions[groupIndex].questions.length){
                validateRequire(idx);
                putAGroupIdx(groupIndex + 1);
                putAQuestionIdx(-1);
                setGroupIndex(groupIndex + 1);
                setQuestionIndex(-1);
            } else if(nextIdx < 0){
                putAQuestionIdx(-1);
                setQuestionIndex(-1);
            } else {
                validateRequire(idx);
                let newIdx = nextIdx;
                putAQuestionIdx(newIdx);
                setQuestionIndex(newIdx);
            }
        } catch(err) {
            message.error(err.message);
        }
    }

    const validateRequire = (idx) => {
        if(idx > questionIndex){
            if(! validateRequireQuestions(questions[groupIndex].questions[questionIndex])) throw new Error('Faltan preguntas por responder');
        }
    }

    if(questions.length === 0){
        return(<Spin />);
    } else
    return(
        <Row>
            <Col lg={24} xs={24}>
                <Row>
                    <Col lg={24} xs={24}>
                    <AHeader />
                    </Col>
                </Row>
                <div style={{ margin: '0 20px' }}>
                    <Row>
                        <Col lg={3} xs={24}>
                        </Col>
                        <Col lg={18} xs={24}>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <span style={{ fontSize: '1.1em', marginTop: '10px', padding: '0 20px' }}>Entrevistado/a: <strong>{survey?.person_name} ({survey?.person_email})</strong></span>
                            </div>
                        </Col>
                        <Col lg={3} xs={24}>
                        </Col>
                    </Row>
                    {
                        getAGroupIdx() >= questions.length ?
                        <Finalize
                            backQuestion={() => {
                                putAGroupIdx(groupIndex - 1);
                                putAQuestionIdx(questions[groupIndex - 1].questions.length - 1);
                                setGroupIndex(groupIndex - 1);
                                setQuestionIndex(questions[groupIndex - 1].questions.length - 1);
                            }}
                        />
                        :
                        questionIndex === -1 ?
                        <Presentation 
                            group={questions[groupIndex]}
                            index={getAGroupIdx()}
                            changeIndex={() => {
                                putAQuestionIdx(0);
                                setQuestionIndex(0);
                            }}
                            backIndex={() => {
                                putAGroupIdx(groupIndex - 1);
                                putAQuestionIdx(questions[groupIndex - 1].questions.length - 1);
                                setGroupIndex(groupIndex - 1);
                                setQuestionIndex(questions[groupIndex - 1].questions.length - 1);
                            }}
                        />
                        :
                        <Survey
                            question={questions[groupIndex].questions[questionIndex]}
                            nextQuestion={() => {
                                changeQuestion(questionIndex + 1);
                            }}
                            backQuestion={() => {
                                changeQuestion(questionIndex - 1, true);
                            }}
                            changeResult={changeResult}
                        />
                    }
                </div>
            </Col>
        </Row>
        
    );
}