import { Link, Outlet, useNavigate } from "react-router-dom";
import { uploads_path, profile_path, login_path, resource_path, resource_category_path, resource_atention_path, users_ies_path } from "../path_pages";
import { cleanValues, getIsAdmin } from "../../_utils/storage_handler";
import Logo1 from '../../_assets/images/banner1.webp';

export default function AdminTemplate(){

    const isAdmin = getIsAdmin();

    const navigate = useNavigate();

    const logout = () => {
        cleanValues();
        navigate(login_path.full_path);
    }

    return(
        <div>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                <img src={Logo1} style={{ width: '70%' }} alt="ASCUN" />
            </div>
            <nav className="navbar navbar-expand-lg" style={{ position: 'sticky', top: 0 }}>
                <div className="container">
                    <div className="d-lg-none ms-auto me-4">
                        <span onClick={logout} style={{ cursor: 'pointer' }} className="navbar-icon bi-arrow-bar-left smoothscroll">
                        </span>
                    </div>
    
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
    
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav ms-lg-5 me-lg-auto">
                            <li className="nav-item dropdown">
                                <span className="nav-link dropdown-toggle" id="navbarLightDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">Banco</span>
                                <ul className="dropdown-menu dropdown-menu-light" aria-labelledby="navbarLightDropdownMenuLink">
                                    <li><Link to={`${resource_path.full_path}`} className="dropdown-item">Búsqueda por territorio</Link></li>
                                    <li><Link to={`${resource_category_path.full_path}`} className="dropdown-item">Búsqueda por categoría</Link></li>
                                    <li><Link to={`${resource_atention_path.full_path}`} className="dropdown-item">Búsqueda por atención</Link></li>
                                </ul>
                            </li>
                            {
                                isAdmin === 1 &&
                                <li className="nav-item">
                                    <Link className="nav-link click-scroll" to={`${uploads_path.full_path}`}>
                                        Cargar experiencias
                                    </Link>
                                </li>
                            }
                            {
                                isAdmin === 1 &&
                                <li className="nav-item">
                                    <Link className="nav-link click-scroll" to={`${users_ies_path.full_path}`}>
                                        Usuarios
                                    </Link>
                                </li>
                            }
                            <li className="nav-item">
                                <Link className="nav-link click-scroll" to={`${profile_path.full_path}`}>
                                    Perfil
                                </Link>
                            </li>
                        </ul>
                        <div className="d-none d-lg-block">
                            <span onClick={logout} style={{ cursor: 'pointer' }} className="navbar-icon bi-arrow-bar-left smoothscroll">
                            </span>
                        </div>
                    </div>
                </div>
            </nav>
            <main style={{ marginBottom: '80px' }}> 
            <Outlet />
            </main>
            <footer className="site-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-12 mt-4 mt-lg-0 ms-auto">
                            <p className="copyright-text mt-lg-5 mt-4">Copyright © ASCUN, Colombia.</p>
                            
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}