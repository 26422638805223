import {
    createResource,
    editResource,
} from '../../_services/resource.service';
import { validateResource } from './model';

export const createResourceEvent = async (body) => {
    validateResource(body);
    return await createResource(body);
}

export const editResourceEvent = async (id, body) => {
    validateResource(body);
    return await editResource(id, body);
}



/** Generated by https://github.com/VictorAndres20 code generator for database, NestJS, React */