import {
    findAllFocusPeople,
    findFocusPeopleById,
    findAllFocusPeoplePaged,
} from '../../_services/focus_people.service';

export const findAllFocusPeopleEvent = async () => {
    return await findAllFocusPeople();
}

export const findFocusPeopleByIdEvent = async (id) => {
    return await findFocusPeopleById(id);
}

export const findAllFocusPeoplePagedEvent = async (page, limit = 8) => {
    return await findAllFocusPeoplePaged(page, limit);
}



/** Generated by https://github.com/VictorAndres20 code generator for database, NestJS, React */