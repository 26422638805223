import {
    findAllIesRegistered,
    findIesRegisteredById,
    findAllIesRegisteredPaged,
} from '../../_services/ies_registered.service';

export const findAllIesRegisteredEvent = async () => {
    return await findAllIesRegistered();
}

export const findIesRegisteredByIdEvent = async (id) => {
    return await findIesRegisteredById(id);
}

export const findAllIesRegisteredPagedEvent = async (page, limit = 8) => {
    return await findAllIesRegisteredPaged(page, limit);
}



/** Generated by https://github.com/VictorAndres20 code generator for database, NestJS, React */