import { Link } from "react-router-dom";
import Table from "./table";
import { upload_form_path } from "../../path_pages";
import IesInfo from "./ies_info";

export default function UploadsPage(){
    return(
        <div>
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px', }}>
                <div style={{ width: '60%' }}>
                    <h6 style={{ marginLeft: '20px' }}>Experiencias cargadas</h6>
                </div>
                <div style={{ width: '30%', display: 'flex', flexDirection: 'row-reverse' }}>
                    <Link className="btn custom-btn custom-border-btn" to={upload_form_path.full_path}>
                        Cargar nuevo
                    </Link>
                </div>
            </div>
            <IesInfo />
            <hr />
            <Table />
        </div>
    );
}