import React from "react";
import Questions from "./questions";
import MainHeader from "../../widgets /headers/main_header";

export default function SurveyPage() {

    return(
        <div>
            <MainHeader />
            <Questions />
        </div>
        
    );
}