import { Col, Input } from "antd";

export default function Opegt({ question, changeResult }){

    return(
        <Col lg={24} xs={24}>
            <table style={{ width: '100%' }}>
                <thead style={{ width: '100%' }}>
                    <tr style={{ width: '100%' }}>
                        <th style={{ width: '2%', borderBottom: '1px solid' }}></th>
                        <th style={{ width: '48%', borderBottom: '1px solid' }}>Ejes</th>
                        <th style={{ width: '48%', borderBottom: '1px solid' }}>Componentes</th>
                    </tr>
                </thead>
            <tbody style={{ width: '100%' }}>
            {
                question?.answers.map((a, key) => {
                    let resultParts = ['', ''];
                    let indexResult = question?.results.findIndex(r => r.answer === a.cod);
                    if(indexResult !== -1){
                        resultParts = question?.results[indexResult]?.value.split('|');
                    }
                    return(
                        <tr key={`yng_tr_${key}`} style={{ width: '100%' }}>
                            <td style={{ width: '4%', borderBottom: '1px solid' }}>
                                {key + 1}
                            </td>
                            <td style={{ width: '48%', borderBottom: '1px solid' }}>
                                <Input
                                    value={ resultParts[0] }
                                    onChange={(e) => {
                                        changeResult(question?.type, a.cod, `${e.target.value ? e.target.value.toUpperCase() : e.target.value}|${resultParts[1]}`)
                                    }}
                                />
                            </td>
                            <td style={{ width: '48%', borderBottom: '1px solid' }}>
                                <Input
                                    value={ resultParts[1] }
                                    onChange={(e) => {
                                        changeResult(question?.type, a.cod, `${resultParts[0]}|${e.target.value ? e.target.value.toUpperCase() : e.target.value}`)
                                    }}
                                />
                            </td>
                        </tr>
                    );
                })
            }
            </tbody>
            </table>            
        </Col>
    );
}