import { Link } from "react-router-dom";
import { login_path } from "../path_pages";
import RegisterForm from "./form";

export default function RegisterPage(){

    return(
        <div style={{ marginBottom: '90px' }}>
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px', }}>
                <div style={{ width: '30%' }}>
                    <Link style={{ marginLeft: '20px' }} className="btn custom-btn custom-border-btn" to={login_path.full_path}>
                        Ir a login
                    </Link>                 
                </div>
                <div style={{ width: '70%', display: 'flex', flexDirection: 'column' }}>
                    <h6>Crear cuenta</h6>
                </div>
            </div>
            <hr />
            <div style={{ margin: '10px 10px' }}>
                <RegisterForm />            
            </div>
        </div>
    );
}