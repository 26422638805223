import {
    findAllAlternative,
    findAlternativeById,
    findAllAlternativePaged,
} from '../../_services/alternative.service';

export const findAllAlternativeEvent = async () => {
    return await findAllAlternative();
}

export const findAlternativeByIdEvent = async (id) => {
    return await findAlternativeById(id);
}

export const findAllAlternativePagedEvent = async (page, limit = 8) => {
    return await findAllAlternativePaged(page, limit);
}



/** Generated by https://github.com/VictorAndres20 code generator for database, NestJS, React */