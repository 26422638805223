import {
    findAllDepartment,
    findDepartmentById,
    findAllDepartmentPaged,
} from '../../_services/department.service';

export const findAllDepartmentEvent = async () => {
    return await findAllDepartment();
}

export const findDepartmentByIdEvent = async (id) => {
    return await findDepartmentById(id);
}

export const findAllDepartmentPagedEvent = async (page, limit = 8) => {
    return await findAllDepartmentPaged(page, limit);
}



/** Generated by https://github.com/VictorAndres20 code generator for database, NestJS, React */