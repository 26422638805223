import {
    findAllAresult,
    findAresultById,
    findAllAresultPaged,
    downloadExcelAresult,
    downloadExcelAresultNames,
} from '../../_services/a_result.service';

export const findAllAresultEvent = async () => {
    return await findAllAresult();
}

export const findAresultByIdEvent = async (id) => {
    return await findAresultById(id);
}

export const findAllAresultPagedEvent = async (page, limit = 8) => {
    return await findAllAresultPaged(page, limit);
}

export const downloadExcelAresultEvent = async () => {
    return await downloadExcelAresult();
}

export const downloadExcelAresultNamesEvent = async () => {
    return await downloadExcelAresultNames();
}



/** Generated by https://github.com/VictorAndres20 code generator for database, NestJS, React */