import { useState } from "react";
import { loginEvent } from "../../_events/login/login.event";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { profile_path, resource_path, uploads_path } from "../../pages/path_pages";

export const useLogin = () => {

    const navigate = useNavigate();

    const [ loading, setLoading  ] = useState(false);
    const [ userInput, setUserInput ] = useState('');
    const [ passwordInput, setPasswordInput ] = useState('');

    const login = () => {
        setLoading(true);
        loginEvent({
            login: userInput,
            password: passwordInput,
        })
        .then(json => {
            setLoading(false);
            if(json.root === 1) navigate(profile_path.full_path);
            else if(json.admin === 1) navigate(uploads_path.full_path);
            else navigate(resource_path.full_path); 
        })
        .catch(err => {
            setLoading(false);
            message.error(err.message);
        });
    }

    return {
        loading, 
        userInput, 
        passwordInput,
        login, 
        setUserInput, 
        setPasswordInput
    }
}