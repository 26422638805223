import {
    findAllImplementationTime,
    findImplementationTimeById,
    findAllImplementationTimePaged,
} from '../../_services/implementation_time.service';

export const findAllImplementationTimeEvent = async () => {
    return await findAllImplementationTime();
}

export const findImplementationTimeByIdEvent = async (id) => {
    return await findImplementationTimeById(id);
}

export const findAllImplementationTimePagedEvent = async (page, limit = 8) => {
    return await findAllImplementationTimePaged(page, limit);
}



/** Generated by https://github.com/VictorAndres20 code generator for database, NestJS, React */