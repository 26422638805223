import {
    findAllIesType,
    findIesTypeById,
    findAllIesTypePaged,
} from '../../_services/ies_type.service';

export const findAllIesTypeEvent = async () => {
    return await findAllIesType();
}

export const findIesTypeByIdEvent = async (id) => {
    return await findIesTypeById(id);
}

export const findAllIesTypePagedEvent = async (page, limit = 8) => {
    return await findAllIesTypePaged(page, limit);
}



/** Generated by https://github.com/VictorAndres20 code generator for database, NestJS, React */