import React from "react";
import { Form } from "react-bootstrap";

export default function UniqueQuestion({ question, changeQuestions = (q) => {} }) {

    const renderUnique = (answer) => {
        return(
            <div>
                <Form.Check
                    checked = {question.results ? question.results.findIndex(r => r.answer === answer.cod ) !== -1 ? true : false : false }
                    onChange={(e) => {
                        const checked = e.target.checked;
                        let newQuestion = {...question};
                        newQuestion.results = [];
                        if(checked) newQuestion.results.push({answer: answer.cod});
                        else {
                            let index = newQuestion.results.findIndex(r => r.answer === answer.cod );
                            if(index !== -1){
                                newQuestion.results.splice(index, 1);
                            }
                        }
                        changeQuestions(newQuestion);
                    }}
                    type={'radio'}
                    label={`${answer.label}`}
                    id={`${answer.cod}`}
            />
            </div>
        );
    }

    const renderUniqueOpen = (answer) => {
        return(
            <div className="flex-row">
                <Form.Check
                    style={{ marginRight: '10px' }}
                    checked = {question.results ? question.results.findIndex(r => r.answer === answer.cod ) !== -1 ? true : false : false }
                    onChange={(e) => {
                        const checked = e.target.checked;
                        let newQuestion = {...question};
                        newQuestion.results = [];
                        if(checked) newQuestion.results.push({answer: answer.cod});
                        else {
                            let index = newQuestion.results.findIndex(r => r.answer === answer.cod );
                            if(index !== -1){
                                newQuestion.results.splice(index, 1);
                            }
                        }
                        changeQuestions(newQuestion);
                    }}
                    type={'radio'}
                    label={`${answer.label}`}
                    id={`${answer.cod}`}
                />
                {
                     question.results && question.results.findIndex(r => r.answer === answer.cod ) !== -1 &&
                     <div className="flex-row">
                        <div style={{ marginRight: '10px' }}>{answer.open_label}</div>
                        <input 
                            value={question.results ? question.results.find(r => r.answer === answer.cod ) ? question.results.find(r => r.answer === answer.cod ).open_answer : '' : '' }
                            onChange={(e) => {
                                let newQuestion = {...question};
                                let index = newQuestion.results.findIndex(r => r.answer === answer.cod );
                                if(index !== -1){
                                    newQuestion.results[index].open_answer = e.target.value;
                                }
                                changeQuestions(newQuestion);
                            }}
                            type="text"
                        />
                     </div>
                }
                
            </div>
        );
    }
    
    return(
        <div>
            {
                question.answers?.map( (answer, key) => {
                    if(answer.type === 'unique') return <div key={`unique_${key}`}>{renderUnique(answer)}</div>;
                    else if(answer.type === 'unique_open') return <div key={`unique_${key}`}>{renderUniqueOpen(answer)}</div>;
                    else return <div key={`unique_${key}`}>Not implemented</div>
                })
            }
        </div>        
    );
}