import {
    findAllIes,
    findIesById,
    findAllIesPaged,
} from '../../_services/ies.service';

export const findAllIesEvent = async () => {
    return await findAllIes();
}

export const findIesByIdEvent = async (id) => {
    return await findIesById(id);
}

export const findAllIesPagedEvent = async (page, limit = 8) => {
    return await findAllIesPaged(page, limit);
}



/** Generated by https://github.com/VictorAndres20 code generator for database, NestJS, React */