import { Outlet } from "react-router-dom";
import Logo1 from '../_assets/images/banner2.webp';

export default function HomeTemplate(){
    return(
        <div>
            <nav className="navbar navbar-expand-lg">
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                <img src={Logo1} style={{ width: '70%' }} alt="ASCUN" />
                </div>
            </nav> 
            <main> 
            <Outlet />
            </main>
            <footer className="site-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-12 mt-4 mt-lg-0 ms-auto">
                            <p className="copyright-text mt-lg-5 mt-4">Copyright © ASCUN, Colombia.</p>
                            
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}