import { Popconfirm } from 'antd';

export default function ConfirmButton({ children, title, description, confirmFunc = () => {} }){

    const confirm = (e) => {
        confirmFunc();
    };
    
    const cancel = (e) => {};

    return(
        <Popconfirm
            title={title}
            description={description}
            onConfirm={confirm}
            onCancel={cancel}
            okText="Sí"
            cancelText="No"
        >
            { children }
        </Popconfirm>
    );
}