import { Badge, Collapse, Result, Spin, theme, message } from "antd";
import PaginationData from "../../widgets /pagination/pagination";
import { InboxOutlined ,SmileOutlined, PlaySquareOutlined, BookOutlined, CodeSandboxOutlined } from "@ant-design/icons";
import { buildDate } from "../../_utils/month_labels";
import { useState } from "react";
import { downloadResourceByPath } from "../../_services/resource.service";
import { downloadBytesInfoFile } from "../../_utils/download_file.util";

const icons = {
    'F1': <PlaySquareOutlined/>,
    'F2': <BookOutlined />,
    'F3': <CodeSandboxOutlined />,
}

export default function Info({ dataHook, count }){
    const { token } = theme.useToken();
    const [ loading, setLoading ] = useState(false);

    const panelStyle = {
        marginBottom: 24,
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        border: 'none',
    };

    const donwload = (path, name) => {
        setLoading(true);
        downloadResourceByPath(path)
        .then(json => {
          setLoading(false);
          downloadBytesInfoFile(json.data.path, name);
        })
        .catch(err => {
          setLoading(false);
          message.error(err.message);
        });
    }

    return(
        <section>
            { dataHook.loading && <Spin /> }
            <PaginationData
                changePage={(page) => {
                    dataHook.setActualPage(page - 1);
                }}
                actualPage={dataHook.actualPage}
                totalData={dataHook.data[1]}
                count={count}
            />
            <div style={{ background: '#eee', paddingBottom: '20px' }} className="container">
                <div className="row">
                    {
                        dataHook.data[0].length === 0 ?
                        <div style={{ margin: '10px 0' }} className="col-12">
                            <div className="custom-block">
                                <Result
                                    icon={<InboxOutlined />}
                                    title="Sin datos con este filtro"
                                />
                            </div>
                        </div>
                        :
                        dataHook.data[0].map((resource, index) => {
                            return(
                                <div key={`card_${index}`} style={{ margin: '10px 0' }} className="col-lg-4 col-12 mb-4 mb-lg-0">
                                    <div className="custom-block bg-white shadow-lg">                                            
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                            <div style={{ width: '100%', fontSize: '0.54em', fontWeight: 'bold', display: 'flex', flexDirection: 'row' }}>
                                                <div style={{ width: '50%' }}>{buildDate(resource.date_created)}</div>
                                                <div style={{ width: '50%', display: 'flex', flexDirection: 'row-reverse' }}>{resource.user_upload?.ies?.city?.department?.name} - {resource.user_upload?.ies?.city?.name}</div>
                                            </div>
                                            <div style={{ fontSize: '0.78em', fontWeight: 'bold' }}>{resource.user_upload?.ies?.ies?.name}</div>
                                            <div style={{ fontSize: '0.78em', fontWeight: 'bold' }}>{resource.user_upload?.ies?.campus ? `SEDE ${resource.user_upload?.ies?.campus}` : ''}</div>
                                            <div style={{ fontSize: '3.8em' }}>
                                            {
                                                resource.type.cod in icons ?
                                                icons[resource.type.cod]
                                                :
                                                <SmileOutlined />
                                            }
                                            </div>                                            
                                            <div style={{ width: '100%', margin: '2px 0' }}>
                                            <Collapse
                                                bordered={false}
                                                style={{
                                                    background: token.colorBgContainer,
                                                }}
                                                size="small"
                                                items={[{ key: '1', label: 'Descripción', children: <p style={{ fontSize: '0.8em' }}>{resource.description}</p>, style: {panelStyle} }]}
                                            />
                                            </div>
                                            <div style={{ width: '100%', margin: '2px 0' }}>
                                            <Collapse
                                                bordered={false}
                                                style={{
                                                    background: token.colorBgContainer,
                                                }}
                                                size="small"
                                                items={[{ key: '1', label: 'Estrategia divulgación', children: <p style={{ fontSize: '0.8em' }}>{resource.name}</p>, style: {panelStyle} }]}
                                            />
                                            </div>
                                            <div style={{ width: '100%', margin: '2px 0' }}>
                                            <Collapse
                                                bordered={false}
                                                style={{
                                                    background: token.colorBgContainer,
                                                }}
                                                size="small"
                                                items={[{ key: '1', label: 'Autores', 
                                                    children: 
                                                        <ul>
                                                            <li style={{ fontSize: '0.7em', fontStyle: 'italic' }}>{resource.author1} - {resource.job1}</li>
                                                            {
                                                                resource.author2 &&
                                                                <li style={{ fontSize: '0.7em', fontStyle: 'italic' }}>{resource.author2} - {resource.job2}</li>
                                                            }
                                                            {
                                                                resource.author3 &&
                                                                <li style={{ fontSize: '0.7em', fontStyle: 'italic' }}>{resource.author3} - {resource.job3}</li>
                                                            }
                                                        </ul>
                                                    , 
                                                    style: {panelStyle} 
                                                }]}
                                            />
                                            </div>
                                            <Badge
                                                className="site-badge-count-109"
                                                count={resource.focus_people?.name}
                                                style={{ backgroundColor: '#E29D75' }}
                                            />
                                            <Badge
                                                className="site-badge-count-109"
                                                count={resource.label.name}
                                                style={{ backgroundColor: '#DD84E3' }}
                                            />
                                            <Badge
                                                className="site-badge-count-109"
                                                count={resource.implementation_time?.name}
                                                style={{ backgroundColor: '#7E67FC' }}
                                            />
                                            <Badge
                                                className="site-badge-count-109"
                                                count={resource.type.name}
                                                style={{ backgroundColor: '#84A3E3', fontSize: '0.7em' }}
                                            />                                            
                                            {
                                                loading ?
                                                <Spin />
                                                :
                                                <span
                                                    onClick={() => {
                                                    donwload(resource.path, `${resource?.user_upload?.ies?.ies?.uuid}_${resource?.type?.cod}`)
                                                    }} 
                                                    className='navbar-icon bi-cloud-download-fill' style={{ cursor: 'pointer', marginTop: '12px' }}>
                                                </span>
                                            }
                                        </div>       
                                        
                                    </div>
                                </div>
                            );
                        })
                    }                   
                </div>
            </div>
            <PaginationData
                changePage={(page) => {
                    dataHook.setActualPage(page - 1);
                }}
                actualPage={dataHook.actualPage}
                totalData={dataHook.data[1]}
                count={count}
                />
        </section>
    );
}