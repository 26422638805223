import { handleFetch, GET_OPTIONS, POST_OPTIONS, PUT_OPTIONS } from './base.service.js';


const BASE_PATH = '/a-result';

export const findAllAresult = async () => {
    return await handleFetch(`${BASE_PATH}/all`, GET_OPTIONS);
}

export const findAresultById = async (id) => {
    return await handleFetch(`${BASE_PATH}/id/${id}`, GET_OPTIONS);
}

export const createAresult = async (body) => {
    return await handleFetch(`${BASE_PATH}/create`, POST_OPTIONS, body);
}

export const editAresult = async (id, body) => {
    return await handleFetch(`${BASE_PATH}/edit/${id}`, PUT_OPTIONS, body);
}

export const findAllAresultPaged = async (page, limit) => {
    return await handleFetch(`${BASE_PATH}/all-paged/${page}/${limit}`, GET_OPTIONS);
}

export const downloadExcelAresult = async () => {
    return await handleFetch(`${BASE_PATH}/excel`, GET_OPTIONS);
}

export const downloadExcelAresultNames = async () => {
    return await handleFetch(`${BASE_PATH}/excel-name`, GET_OPTIONS);
}


/** Generated by https://github.com/VictorAndres20 code generator for database, NestJS, React */