import React from "react";
import { Col, Container, Form, Row, Table } from "react-bootstrap";
import { findAllAlternativeEvent } from "../../_events/alternative/find.event";
import { findAllAlternativeStatesEvent } from "../../_events/alternative_states/find.event";

export default function AlternativeQuestion({ question, changeQuestions = (q) => {} }) {

    const [ alternatives, setAlternatives ] = React.useState([]);
    const [ states, setStates ] = React.useState([]);

    React.useEffect(() => {
        findAllAlternativeEvent()
        .then(json => {
            setAlternatives(json.list);
        })
        .catch(err => {
            alert(err.message);
        });

        findAllAlternativeStatesEvent()
        .then(json => {
            setStates(json.list);
        })
        .catch(err => {
            alert(err.message);
        });

    }, []);


    const renderCheck = (alter, state) => {
        return(
            <div className="flex-col flex-center" style={{ width: '100%' }}>
                <input 
                    checked = {question.alternatives ? question.alternatives.findIndex(r => r.alternative === alter.cod && r.state === state.cod ) !== -1 ? true : false : false }
                    onChange={(e) => {
                        let newQuestion = {...question};
                        if(! newQuestion.alternatives) newQuestion.alternatives = [];
                        let indexA = newQuestion.alternatives.findIndex( a => a.alternative === alter.cod );
                        if(indexA !== -1){
                            newQuestion.alternatives[indexA].state = state.cod;
                        } else {
                            newQuestion.alternatives.push({alternative: alter.cod, state: state.cod});
                        }
                        changeQuestions(newQuestion);
                    }}
                    type="radio"
                />
            </div>
        );
    }
    
    return(
        <Table responsive>
            <thead>
                <tr>
                    <th style={{ fontSize: '0.8em' }}>
                    Alternativas
                    </th>
                    {
                        states.map((dep, key) => (
                            <th style={{ fontSize: '0.8em' }} key={`title_${key}`}>
                                {dep.name}
                            </th>
                        ))
                    }
                </tr>                
            </thead>
            <tbody>
            {
                alternatives.map((action, key) => {
                    return(
                        <tr key={`action_${key}`}>
                            <td style={{ fontSize: '0.8em' }}>{action.name}</td>  
                            {
                                states.map((dep, key) => (
                                    <th key={`dep_${key}`}>
                                        {renderCheck(action, dep)}
                                    </th>
                                ))
                            }   
                        </tr>               
                    );
                })
            }
            </tbody>
            <div style={{ marginTop: '18px' }}>
                <div style={{ fontWeight: 'bold', fontSize: '0.85em' }} >Otros</div>
                <Container fluid>
                    <Row>
                        <Col lg={1}>
                            <div style={{ fontWeight: 'bold', fontSize: '0.85em' }}>¿Cuáles?</div>
                        </Col>
                        <Col lg={11}>
                            <Form.Control 
                                type="text"
                                onChange={(e) => {
                                    let newQuestion = {...question};
                                    newQuestion.other_alternatives = e.target.value;
                                    changeQuestions(newQuestion);
                                }}
                                value={ question ? question.other_alternatives ? question.other_alternatives : '' : '' }
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <div style={{ fontWeight: 'bold', fontSize: '0.85em' }}>Nota: Agradecemos nos comparta el link con información relacionada</div>
                        </Col>
                        <Col lg={12}>
                            <Form.Control 
                                type="text"
                                onChange={(e) => {
                                    let newQuestion = {...question};
                                    newQuestion.link_alternative = e.target.value;
                                    changeQuestions(newQuestion);
                                }}
                                value={ question ? question.link_alternative ? question.link_alternative : '' : '' }
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        </Table>      
    );
}