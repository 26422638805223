import {
    createSurvey,
    editSurvey,
    createTransactionSurvey,
} from '../../_services/survey.service';
import { validateSurvey } from './model';

export const createSurveyEvent = async (body) => {
    validateSurvey(body);
    return await createSurvey(body);
}

export const editSurveyEvent = async (id, body) => {
    validateSurvey(body);
    return await editSurvey(id, body);
}

export const createTransactionSurveyEvent = async (body) => {
    return await createTransactionSurvey(body);
}



/** Generated by https://github.com/VictorAndres20 code generator for database, NestJS, React */