import {
    findAllCity,
    findCityById,
    findAllCityPaged,
    findAllCityByDep,
} from '../../_services/city.service';

export const findAllCityEvent = async () => {
    return await findAllCity();
}

export const findCityByIdEvent = async (id) => {
    return await findCityById(id);
}

export const findAllCityByDepEvent = async (id) => {
    return await findAllCityByDep(id);
}

export const findAllCityPagedEvent = async (page, limit = 8) => {
    return await findAllCityPaged(page, limit);
}



/** Generated by https://github.com/VictorAndres20 code generator for database, NestJS, React */