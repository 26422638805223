import { Row, Col } from 'antd';
import MainHeader from "../../widgets /headers/main_header";
import StartASurveyForm from './form';

export default function StartASurveyPage() {

    return(
        <Row>
            <Col lg={24} xs={24}>
                <Row>
                    <Col lg={24} xs={24}>
                    <MainHeader path={'banner.png'} />
                    </Col>
                </Row>
                <div style={{ margin: '20px 20px' }}>
                    <Row>
                        <Col lg={3} xs={24}>
                        </Col>
                        <Col lg={18} xs={24}>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <span style={{ fontSize: '1.9em', fontWeight: 'bold', textAlign: 'center' }}>Encuesta análisis a profundidad de la implementación de la guía e identificación de capacidades institucionales para la permanencia y graduación estudiantil</span>
                                <span style={{ fontSize: '1.1em', marginTop: '10px', padding: '0 20px' }}>La Asociación Colombiana de Universidades (ASCUN) suscribió el contrato número co1.pccntr.5354594 de 2023, por medio del cual se busca la actualización de los lineamientos contenidos en la guía para la implementación del modelo de gestión de la permanencia y la graduación estudiantil en instituciones de educación superior y la construcción de herramientas de orientación socio ocupacional en clave de trayectoria educativa.</span>
                                <span style={{ fontSize: '1.1em', marginTop: '10px', padding: '0 20px' }}>Dentro de las actividades previstas se realizará una identificación sobre el nivel de apropiación y conocimiento de las estrategias establecidas en Guía y la capacidad institucional desarrolladas para la gestión de la permanencia y graduación estudiantil. El enfoque de evaluación de capacidades en este estudio comprende cuatro factores: planificación, recursos financieros, humanos y físicos, cultura y efectividad.</span>
                            </div>
                        </Col>
                        <Col lg={3} xs={24}>
                        </Col>
                        <Col lg={2} xs={24}>
                        </Col>
                        <StartASurveyForm />
                        <Col lg={2} xs={24}>
                        </Col>
                    </Row>
                </div>
            </Col>
        </Row>
        
    );
}