import IesInfo from "../uploads/ies_info";
import Form from "./form";

export default function ProfilePage(){
    return(
        <div>
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px', }}>
                <div style={{ width: '60%' }}>
                    <h6 style={{ marginLeft: '20px' }}>Perfil</h6>
                </div>
            </div>
            <IesInfo />
            <hr />
            <Form />
        </div>
    );
}