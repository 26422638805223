import {
    findAllKeyword,
    findKeywordById,
    findAllKeywordPaged,
} from '../../_services/keyword.service';

export const findAllKeywordEvent = async () => {
    return await findAllKeyword();
}

export const findKeywordByIdEvent = async (id) => {
    return await findKeywordById(id);
}

export const findAllKeywordPagedEvent = async (page, limit = 8) => {
    return await findAllKeywordPaged(page, limit);
}



/** Generated by https://github.com/VictorAndres20 code generator for database, NestJS, React */