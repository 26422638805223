import React from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { getCampus, getIes, getIesNaturaly, getIesType, getPerson } from "../../_utils/storage_handler";
import { createTransactionSurveyEvent } from "../../_events/survey/create.event";

export default function ResultModal({ questions }){

    const [show, setShow] = React.useState(false);
    const [show2, setShow2] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => {
        setShow2(true);
        setTimeout(() => {
            document.location.href = '/';
        }, 3000);
    }

    const validateAnswerArray = (question, key) => {
        if(!question[`${key}`]) return false;
        if(question[`${key}`].length === 0) return false;
        return true;
    }

    const buildAnswers = () => {
        let answered = {
            ies_dto: getIes(),
            ies_naturaly_dto: getIesNaturaly(),
            ies_type_dto: getIesType(),
            person_dto: getPerson(),
            results: [],
            alternatives: [],
            scalation: [],
            components: [],
            other_alternative: null,
            link_alternative: null,
            campus: getCampus(),
        };
        for (let index = 0; index < questions.length; index++) {
            const question = questions[index];
            if(question.type === 'unique' || question.type === 'open') {
                // results
                if(validateAnswerArray(question, 'results')) answered.results.push(...question.results);
            }
            else if(question.type === 'altern') {
                //alternatives
                if(validateAnswerArray(question, 'alternatives')) {
                    answered.alternatives.push(...question.alternatives);
                    answered.other_alternative = question.other_alternatives;
                    answered.link_alternative = question.link_alternative;
                }
            }
            else if(question.type === 'comp') {
                //alternatives
                if(validateAnswerArray(question, 'results')) answered.components.push(...question.results);
            }
            else if(question.type === 'scale_c' || question.type === 'scale_a' ) {
                // scalation
                if(validateAnswerArray(question, 'scalation')) answered.scalation.push(...question.scalation);
            }     
        }
        return answered;
    }

    const sendResults = () => {
        const body = buildAnswers();
        setLoading(true);
        createTransactionSurveyEvent(body)
        .then(json => {
            setLoading(false);
            handleClose();
            handleShow2();
        })
        .catch(err => {
            setLoading(false);
            alert(err.message);
        })
    }
    
    const buildNotAnswered = () => {
        let not_answered = [];
        for (let index = 0; index < questions.length; index++) {
            const question = questions[index];
            if(question.type === 'unique' || question.type === 'open' || question.type === 'comp') {
                // results
                if(! validateAnswerArray(question, 'results')) not_answered.push(question);
            }
            else if(question.type === 'altern') {
                //alternatives
                if(! validateAnswerArray(question, 'alternatives')) not_answered.push(question);
            }
            else if(question.type === 'scale_c' || question.type === 'scale_a' ) {
                // scalation
                if(! validateAnswerArray(question, 'scalation')) not_answered.push(question);
            }     
        }
        return not_answered;
    }

    const renderBody = () => {
        const not_answered = buildNotAnswered();
        if(not_answered.length === 0){
            return(
                <div>
                    <div style={{ fontWeight: 'bold', color: 'green' }}>
                        Todas las preguntas fueron contestadas
                    </div>
                    <div style={{ fontWeight: 'bold', color: 'green' }}>
                        Por favor, guradar las respuestas dando click en "Enviar encuesta"
                    </div>
                </div>
            );
        }
        return(
            <div>
                <span style={{ fontWeight: 'bold', color: 'red' }}>Falta por contestar:</span>
                {
                    not_answered.map((question, key) => {
                        return(
                            <ul key={`ques_${key}`}>
                                <li style={{ fontSize: '0.8em' }}>{question.label}</li>
                            </ul>
                        );
                    })
                }
            </div>
        );

    }

    return(
        <div>
            <Button variant="primary" onClick={handleShow}>
                Continuar
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>Resultados</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        renderBody()
                    }
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                </Button>
                {
                    buildNotAnswered().length === 0 &&
                    (
                        loading ?
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                        :
                        <Button variant="primary" onClick={sendResults}>
                            Enviar encuesta
                        </Button>
                    )
                }
                </Modal.Footer>
            </Modal>
            <Modal show={show2} onHide={handleClose2}>
                <Modal.Header closeButton>
                <Modal.Title>Resultados enviados</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div style={{ fontWeight: 'bold', color: 'green' }}>
                    ¡Preguntas enviadas!
                </div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose2}>
                    Cerrar
                </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}