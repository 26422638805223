import {
    findAllResourceType,
    findResourceTypeById,
    findAllResourceTypePaged,
} from '../../_services/resource_type.service';

export const findAllResourceTypeEvent = async () => {
    return await findAllResourceType();
}

export const findResourceTypeByIdEvent = async (id) => {
    return await findResourceTypeById(id);
}

export const findAllResourceTypePagedEvent = async (page, limit = 8) => {
    return await findAllResourceTypePaged(page, limit);
}



/** Generated by https://github.com/VictorAndres20 code generator for database, NestJS, React */