import {
    findAllIesRepo,
    findIesRepoById,
    findAllIesRepoPaged,
} from '../../_services/ies_repo.service';

export const findAllIesRepoEvent = async () => {
    return await findAllIesRepo();
}

export const findIesRepoByIdEvent = async (id) => {
    return await findIesRepoById(id);
}

export const findAllIesRepoPagedEvent = async (page, limit = 8) => {
    return await findAllIesRepoPaged(page, limit);
}



/** Generated by https://github.com/VictorAndres20 code generator for database, NestJS, React */