import { validateEmail } from "../../_utils/validate_email";

export const validateAsurvey = (body) => {
    const { person_name, person_email, ies} = body;
    if(person_name === null || person_name === '') throw new Error('Nombre y apellidos vacío');
    if(person_email === null || person_email === '') throw new Error('Correo vacío');
    if(ies === null || ies === '') throw new Error('IES vacía');
    if(! validateEmail(person_email)) throw new Error('Correo electrónico no es válido'); 
}

export const transformEntityAsurvey = (entity) => {
    let newEnt = {...entity}
    newEnt.ies = newEnt.ies?.uuid;
    return newEnt;
}

export const buildEmptyAsurvey = () => {
    return {
        date_created: new Date(),
        person_name: '',
        person_email: '',
        person_job: '',
        ies: '',
        minutes_taken: 0,
    };
}



/** Generated by https://github.com/VictorAndres20 code generator for database, NestJS, React */