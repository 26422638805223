import { Col, Input, Radio } from "antd";

export default function Ynm({ question, changeResult }){

    return(
        <Col lg={24} xs={24}>
            <table style={{ width: '100%' }}>
            <tbody style={{ width: '100%' }}>
            {
                question?.answers.map((a, key) => {
                    const indexYes = question?.results.findIndex( r => r.answer === a.cod && r.value === 'Sí' );
                    return(
                        <tr key={`yng_tr_${key}`} style={{ width: '100%' }}>
                            <td style={{ width: '50%' }}>
                                {a.label}
                                {
                                    indexYes !== -1 && a.type.cod === 'ynmo' &&
                                    <Input
                                        placeholder="Cuál"
                                        value={ question?.results[indexYes]?.addition_value }
                                                onChange={(e) => {
                                                    changeResult('yngo', a.cod, e.target.value ? e.target.value.toUpperCase() : e.target.value);
                                                }}
                                    />
                                }
                            </td>
                            <td style={{ width: '10%' }}>
                                <Radio
                                    checked={question?.results.findIndex( r => r.answer === a.cod && r.value === 'Sí' ) !== -1 }
                                    onChange={(e) => {
                                        changeResult(question?.type, a.cod, 'Sí');
                                    }}
                                >
                                    Sí
                                </Radio>
                            </td>
                            <td style={{ width: '10%' }}>
                                <Radio
                                    checked={question?.results.findIndex( r => r.answer === a.cod && r.value === 'No' ) !== -1 }
                                    onChange={(e) => {
                                        changeResult(question?.type, a.cod, 'No');
                                    }}
                                >
                                    No
                                </Radio>
                            </td>
                            <td style={{ width: '30%' }}>
                                <Radio
                                    checked={question?.results.findIndex( r => r.answer === a.cod && r.value === 'No tiene programa' ) !== -1 }
                                    onChange={(e) => {
                                        changeResult(question?.type, a.cod, 'No tiene programa');
                                    }}
                                >
                                    <span style={{ fontSize: '0.85em' }}>No tiene el programa</span>
                                </Radio>
                            </td>
                        </tr>
                    );
                })
            }
            </tbody>
            </table>            
        </Col>
    );
}