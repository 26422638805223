import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { useExcelPerson } from "../../_hooks/person/useExcelPerson.hook";

const pass = "Ascun2023**";

export default function Report(){

    const downloadHook = useExcelPerson();

    return(
        <Container>
            <Row>
                <Col lg={4}>
                </Col>
                <Col lg={4}>
                    <div className="flex-col flex-center">
                        <Card>
                            <Card.Body>
                                <div className="flex-col flex-center">
                                    <span style={{ margin: '10px 0', fontSize: '1.1em' }}>Descargar el reporte Exel aquí</span>
                                    {
                                        downloadHook.loading ?
                                        <div className="vp-spinner"></div>
                                        :
                                        <Button onClick={() => {
                                            let password = prompt("Contraseña");
                                            if(password === pass){
                                                downloadHook.downloadExcel();
                                            } else {
                                                alert("Contraseña incorrecta");
                                            }
                                        }} variant="success">
                                            Descargar
                                        </Button>
                                    }
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                </Col>
                <Col lg={4}>
                </Col>
                <Col lg={4}></Col>
            </Row>
        </Container>
    );
} 