export const validatePerson = (body) => {
    //const {  } = body;
}

export const transformEntityPerson = (entity) => {
    let newEnt = {...entity}
    newEnt.job_position = newEnt.job_position?.uuid;
    return newEnt;
}

export const buildEmptyPerson = () => {
    return {
        uuid: '',
        email: '',
        phone: '',
        job_position_open: '',
        job_position: '',
    };
}



/** Generated by https://github.com/VictorAndres20 code generator for database, NestJS, React */