import { getUserId } from '../_utils/storage_handler.js';
import { handleFetch, GET_OPTIONS, POST_OPTIONS, PUT_OPTIONS, DELETE_OPTIONS } from './base.service.js';


const BASE_PATH = '/resource';

export const findAllResource = async () => {
    return await handleFetch(`${BASE_PATH}/all`, GET_OPTIONS);
}

export const findResourceById = async (id) => {
    return await handleFetch(`${BASE_PATH}/id/${id}`, GET_OPTIONS);
}

export const downloadResourceByPath = async (path) => {
    return await handleFetch(`${BASE_PATH}/download-path/${path}`, GET_OPTIONS);
}

export const removeResourceByPath = async (path) => {
    return await handleFetch(`${BASE_PATH}/remove/${path}`, DELETE_OPTIONS);
}

export const createResource = async (body) => {
    return await handleFetch(`${BASE_PATH}/create-upload`, POST_OPTIONS, body);
}

export const editResource = async (id, body) => {
    return await handleFetch(`${BASE_PATH}/edit/${id}`, PUT_OPTIONS, body);
}

export const findAllResourcePaged = async (page, limit) => {
    return await handleFetch(`${BASE_PATH}/all-paged/${page}/${limit}`, GET_OPTIONS);
}

export const findAllResourceOwnerPaged = async (page, limit) => {
    return await handleFetch(`${BASE_PATH}/owner/${page}/${limit}/${getUserId()}`, GET_OPTIONS);
}

export const findAllResourceUserPaged = async (page, limit, type, label) => {
    return await handleFetch(`${BASE_PATH}/user/${page}/${limit}/${type}/${label}`, GET_OPTIONS);
}

export const findAllResourceUserByIesPaged = async (page, limit, type, label, ies) => {
    return await handleFetch(`${BASE_PATH}/user/ies/${page}/${limit}/${type}/${label}/${ies}`, GET_OPTIONS);
}

export const findAllResourceTerritoryPaged = async (page, limit, filter) => {
    return await handleFetch(`${BASE_PATH}/territory/${page}/${limit}/${filter}`, GET_OPTIONS);
}

export const findAllResourceCategoryPaged = async (page, limit, filter) => {
    return await handleFetch(`${BASE_PATH}/category/${page}/${limit}/${filter}`, GET_OPTIONS);
}

export const findAllResourceAtentionPaged = async (page, limit, filter) => {
    return await handleFetch(`${BASE_PATH}/atention/${page}/${limit}/${filter}`, GET_OPTIONS);
}

export const downloadTermsPdf = async (type) => {
    return await handleFetch(`${BASE_PATH}/terms/${type}`, GET_OPTIONS);
}



/** Generated by https://github.com/VictorAndres20 code generator for database, NestJS, React */