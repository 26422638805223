import {crypt, decrypt} from './crypt';

const TOKEN_KEY = 'qwer';
const COMPANY_KEY = 'asdf';
const IES_KEY = 'alisy';
const IES_NATURALY_KEY = 'inisy';
const IES_TYPE_KEY = 'itisy';
const PERSON_KEY = 'peisy';
const QUESTIONS_KEY = 'wadfh';
const CAMPUS_KEY = 'xqazth';
const A_SURVEY_KEY = 'asury';
const A_RESULT_KEY = 'asres';
const A_QUESTIONS_KEY = 'asque';
const A_QUESTION_IDX_KEY = 'asqueidx';
const A_GROUP_IDX_KEY = 'asgroidx';
const ADMIN_KEY = 'adkere';
const ROOT_KEY = 'alskder';
const USER_ID_KEY = 'zxcvre';
const ROL_ID_KEY = 'uiopre';
const IES_ID_KEY = 'iesopre';

export const putValue = (key, value) => {
    window.localStorage.setItem(key, crypt(value.toString()));
}

export const getValue = (key) => {
    let data = window.localStorage.getItem(key);
    if(data != null) return decrypt(data);
    else return data;
}

export const putValueObj = (key, value) => {
    window.localStorage.setItem(key, crypt(JSON.stringify(value)));
}

export const getValueObj = (key) => {
    let data = window.localStorage.getItem(key);
    if(data != null) return JSON.parse(decrypt(data));
    else return data;
}

export const cleanValue = (key) => {
    window.localStorage.removeItem(key);
}

export const cleanValues = () => {
    window.localStorage.clear();
}

export const putToken = (token) => {
    putValue(TOKEN_KEY, token);
}

export const getToken = () => {
    return getValue(TOKEN_KEY);
}

export const putCompany = (id) => {
    putValue(COMPANY_KEY, id);
}

export const getCompany = () => {
    return getValue(COMPANY_KEY);
}

export const putCampus = (id) => {
    putValue(CAMPUS_KEY, id);
}

export const getCampus = () => {
    return getValue(CAMPUS_KEY);
}

export const putIes = (entity) => {
    putValueObj(IES_KEY, entity);
}

export const getIes = () => {
    return getValueObj(IES_KEY);
}

export const putIesNaturaly = (entity) => {
    putValueObj(IES_NATURALY_KEY, entity);
}

export const getIesNaturaly = () => {
    return getValueObj(IES_NATURALY_KEY);
}

export const putIesType = (entity) => {
    putValueObj(IES_TYPE_KEY, entity);
}

export const getIesType = () => {
    return getValueObj(IES_TYPE_KEY);
}

export const putPerson = (entity) => {
    putValueObj(PERSON_KEY, entity);
}

export const getPerson = () => {
    return getValueObj(PERSON_KEY);
}

export const putQuestions = (entity) => {
    putValueObj(QUESTIONS_KEY, entity);
}

export const getQuestions = () => {
    return getValueObj(QUESTIONS_KEY);
}

export const putAsurvey = (entity) => {
    putValueObj(A_SURVEY_KEY, entity);
}

export const getAsurvey = () => {
    return getValueObj(A_SURVEY_KEY);
}

export const putAresult = (entity) => {
    putValueObj(A_RESULT_KEY, entity);
}

export const getAresult = () => {
    return getValueObj(A_RESULT_KEY);
}

export const putAquestions = (entity) => {
    putValueObj(A_QUESTIONS_KEY, entity);
}

export const getAquestions = () => {
    return getValueObj(A_QUESTIONS_KEY);
}

export const putAQuestionIdx = (value) => {
    putValue(A_QUESTION_IDX_KEY, value.toString());
}

export const getAQuestionIdx = () => {
    return Number(getValue(A_QUESTION_IDX_KEY));
}

export const putAGroupIdx = (value) => {
    putValue(A_GROUP_IDX_KEY, value.toString());
}

export const getAGroupIdx = () => {
    return Number(getValue(A_GROUP_IDX_KEY));
}

export const putIsAdmin = (id) => {
    putValue(ADMIN_KEY, id);
}

export const getIsAdmin = () => {
    return Number(getValue(ADMIN_KEY));
}

export const putIsRoot = (name) => {
    putValue(ROOT_KEY, name);
}

export const getIsRoot = () => {
    return Number(getValue(ROOT_KEY));
}

export const putUserId = (id) => {
    putValue(USER_ID_KEY, id);
}

export const getUserId = () => {
    return getValue(USER_ID_KEY);
}

export const putIesId = (id) => {
    putValue(IES_ID_KEY, id);
}

export const getIesId = () => {
    return getValue(IES_ID_KEY);
}

export const putRol = (id) => {
    putValue(ROL_ID_KEY, id);
}

export const getRol = () => {
    return getValue(ROL_ID_KEY);
}