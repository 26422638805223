import { buildEmptyIesRegistered, validateIesRegistered } from "../ies_registered/model";

export const validate = (body, isAdmin = false) => {
    const { full_name, email, ies } = body;
    if(full_name === '' || full_name === null) throw new Error('Nombre completo vacío');
    if(email === '' || email === null) throw new Error('Correo institucional vacío');
    if(!isAdmin && (ies === '' || ies === null)) throw new Error('Institución de Educación Superior vacía');
}

export const validateUser = (body, isAdmin = false) => {
    const { password } = body;
    if(password === '' || password === null) throw new Error('Contraseña vacía');
    validate(body, isAdmin);
}

export const validateEdition = (body, isAdmin = false) => {
    validate(body, isAdmin);
}

export const validateUserAdmin = (body) => {
    validateUser(body, true);
    validateIesRegistered(body.ies_registered);
}

export const transformEntityUser = (entity) => {
    let newEnt = {...entity}
    newEnt.password = '';
    return newEnt;
}

export const buildEmptyUser = () => {
    return {
        full_name: '',
        phone: '',
        email: '@',
        login: '',
        password: '',
        active: 1,
        admin: 0,
        root: 0,
        ies: '',
        dependency: '',
        job: ''
    };
}

export const buildEmptyUserWithIes = () => {
    return {
        ...buildEmptyUser(),
        ies_registered: {
            ...buildEmptyIesRegistered(),
        }
    };
}



/** Generated by https://github.com/VictorAndres20 code generator for database, NestJS, React */