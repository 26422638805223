import { Card } from "antd";
import LoginForm from "./form";
import { Link } from "react-router-dom";
import { register_path } from "../path_pages";

export default function LoginPage(){
    return(
        <section className="hero-section d-flex justify-content-center align-items-center" id="section_1">
            <div className="container">
                <div className="row">
                    <div style={{ marginTop: '-140px' }} className="col-lg-8 col-12 mx-auto">
                        <h1 style={{ color: '#555' }} className="text-center">Banco de experiencias significativas</h1>
                        <h6 style={{ marginBottom: '20px' }} className="text-center">Experiencias innovadoras de permanencia y graduación</h6>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <LoginForm />
                            <div style={{ margin: '9px 0' }}>
                            <Card style={{ width: 330 }}>
                                <div className="d-flex justify-content-center align-items-center" style={{ fontSize: '0.95em', fontWeight: 'bold' }}>¿No tienes cuenta? <Link style={{ marginLeft: '10px' }} to={register_path.full_path}>Regístrate aquí</Link></div>
                            </Card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}