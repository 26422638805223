import {
    findAllAsurvey,
    findAsurveyById,
    findAllAsurveyPaged,
    validateAsurveyByEmailIes
} from '../../_services/a_survey.service';

export const findAllAsurveyEvent = async () => {
    return await findAllAsurvey();
}

export const findAsurveyByIdEvent = async (id) => {
    return await findAsurveyById(id);
}

export const validateAsurveyByEmailIesEvent = async (email, ies) => {
    return await validateAsurveyByEmailIes(email, ies);
}

export const findAllAsurveyPagedEvent = async (page, limit = 8) => {
    return await findAllAsurveyPaged(page, limit);
}



/** Generated by https://github.com/VictorAndres20 code generator for database, NestJS, React */