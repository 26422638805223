import { Col, Input, Radio, Row } from "antd";

export default function Uniq({ question, changeResult }){

    return(
        <Col lg={24} xs={24}>
            <Row>
                <Col lg={4} xs={24}>
                </Col>
                <Col lg={16} xs={24}>
                    <table style={{ width: '100%' }}>
                    <tbody style={{ width: '100%' }}>
                    {
                        question?.answers.map((a, key) => {
                            const result = question?.results.find( r => r.answer === a.cod );
                            return(
                                <tr key={`yng_tr_${key}`} style={{ width: '100%' }}>
                                    <td style={{ width: '100%' }}>
                                        <Radio
                                            checked={ result ? result.value !== '' ? true : false : false  }
                                            onChange={() => {
                                                changeResult(question?.type, a.cod, a.label);
                                            }}
                                        >
                                            {a.label}
                                        </Radio>
                                        {
                                            result && a.type.cod === 'uniqo' &&
                                            <Input
                                                placeholder="Cuál"
                                                value={ result?.addition_value }
                                                onChange={(e) => {
                                                    changeResult(a.type.cod, a.cod, e.target.value ? e.target.value.toUpperCase() : e.target.value);
                                                }}
                                            />
                                        }
                                    </td>
                                </tr>
                            );
                        })
                    }
                    </tbody>
                    </table>            
                </Col>
                <Col lg={4} xs={24}>
                </Col>
            </Row>
        </Col>
    );
}